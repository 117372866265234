<template>
<div title="添加权限" class="add-perm-container">
  <div class="add_perm_box">
      <el-form class="perm_add_form" :model="permissionAddForm"
               :rules="permissionAddRules" ref="permissionAddFormRef">
        <p class="perm-add-head">添加权限</p>
        <el-form-item label="权限名" class="color-item" prop="name"
                      id="perm_name" :rules="permissionAddRules.perm_name">
          <el-input type="text" v-model="permissionAddForm.name" placeholder="权限名"
                    prefix-icon="el-icon-s-check"></el-input>
        </el-form-item>
        <el-form-item label="权限编码" class="color-item" prop="code"
                      id="perm_code" :rules="permissionAddRules.code">
          <el-input type="text" v-model="permissionAddForm.code" placeholder="权限编码"
                    prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item label="后端接口" class="color-item" prop="uri" id="perm_uri">
          <el-input type="text" v-model="permissionAddForm.uri" placeholder="后端接口"
                    prefix-icon="el-icon-link"></el-input>
        </el-form-item>
        <el-form-item label="前端路由" class="color-item" prop="path" id="perm_path">
          <el-input type="text" v-model="permissionAddForm.path" placeholder="前端路由"
                    prefix-icon="el-icon-link"></el-input>
        </el-form-item>
        <el-form-item label="图标" class="color-item" prop="icon" id="perm_icon">
          <el-input type="text" v-model="permissionAddForm.icon" placeholder="图标"
                    prefix-icon="el-icon-s-tools"></el-input>
        </el-form-item>
        <el-form-item label="权限类型" class="color-item" prop="type" id="perm_type">
        <el-select id="perm-status-select" v-model="permissionAddForm.type" required
                   filterable
                   style="width: 100%"
                   placeholder="请选择权限类型"
                   clearable>
          <el-option
              v-for="(item, index) in permType"
              :key="index"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="权限状态" class="color-item" prop="perm_status" id="perm_status">
        <el-select id="perm-status-select" v-model="permissionAddForm.status" required
                   filterable
                   style="width: 100%"
                   placeholder="请选择权限状态"
                   clearable>
          <el-option
              v-for="(item, index) in permStatus"
              :key="index"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="上级权限" class="color-item" prop="perm_status" id="perm_status">
        <el-select id="perm-status-select" v-model="permissionAddForm.parent_id"
                   filterable
                   style="width: 100%"
                   placeholder="请选择上级权限"
                   clearable>
          <el-option
              v-for="(item, index) in parents"
              :key="index"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" :disabled="isAdd" @click="handleAddPerm">添加</el-button>
          <el-button @click="closeAddPerm">取消</el-button>
        </el-form-item>
      </el-form>
  </div>
</div>
</template>

<script>
import {addAuthority, getParentAuthorities} from "../../api/user";

export default {
  name: "AddPermission",
  data() {
    const permStatus = [
      {id: 0, name: "禁用"},
      {id: 1, name: "启用"},
    ]
    const permType = [
      {id: 1, name: "目录"},
      {id: 2, name: "菜单"},
      {id: 3, name: "按钮"},
    ]
    const validateName = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入权限名'))
       } else {
         if (value.length > 32 || value.length < 3) {
           callback(new Error('请输入小于3-32位权限名'))
         } else {
           callback()
         }
       }
     }
    const validateCode = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输权限编码'))
       } if (value.length > 32 || value.length < 4) {
           callback(new Error('请输入小于4-32位权限编码'))
       } else {
         callback()
       }
     }
    return {
      permStatus,
      permType,
      parents: [],
      permissionAddForm: {
        name: "",
        code: "",
        type: 1,
        uri: "",
        path: "",
        icon: "",
        status: 1,
        parent_id: "",
      },
      permissionAddRules: {
         perm_name: [
             { required: true, message: '请输入权限名', trigger: 'blur' },
             { min: 3, max: 20, message: '长度在 3 到 32 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateName }],
         code: [
             { required: true, message: '请输入权限编码', trigger: 'blur' },
             { min: 1, max: 20, message: '长度在 4 到 32 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateCode }],
       },
      
    }
  },
  mounted() {
    this.getParentPerms()
  },

  computed: {
    isAdd() {
        return !this.permissionAddForm.name || !this.permissionAddForm.code ||
            !this.permissionAddForm.type || !this.permissionAddForm.status
    }
  },

  methods: {
    async handleAddPerm() {
      const form = {
        name: this.permissionAddForm.name,
        code: this.permissionAddForm.code,
        status: this.permissionAddForm.status,
        type: this.permissionAddForm.type,
        uri: this.permissionAddForm.uri,
        path: this.permissionAddForm.path,
        icon: this.permissionAddForm.icon,
        parent_id: this.permissionAddForm.parent_id,
      }

      await this.$refs['permissionAddFormRef'].validate((valid) => {
           if (valid) {
             addAuthority(form).then(res => {
             if (res.code !== 200) {
               return this.$message.error('Add Permission Failed: ' + res.message)
             }
             this.$message.success('Message: ' + res.message);
             this.$router.push('permission-list').catch(() => {});
             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + ex.message)
             }
             })
           } else {
             // this.$refs.userAddFormRef.resetFields();
             return this.$message.error("Please Input Valid Request Data!")
           }
         })
    },
    closeAddPerm() {
      this.$refs.permissionAddFormRef.resetFields();
      this.$router.go(-1);
    },
    getParentPerms() {
      getParentAuthorities().then(res => {
        if (res.code !== 200) {
               return this.$message.error('Get Parent Perms Failed: ' + res.message)
        }
        this.parents = res.data
      })
    },
  }
}
</script>

<style scoped>
.add_perm_box {
  width: 40%;
  height: 700px;
  position: absolute;
  top: -28%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  border-radius: 20px;
  margin-top: 20px;
}

.perm_add_form {
  text-align: center;
  margin-top: 10%;
}

.perm-add-head {
  color: greenyellow;
  font-size: 20px;
}

#perm_name, #perm_code, #perm_status, #perm_uri,
#perm_path, #perm_icon, #perm_type {
  width: 50%;
  margin: 25px auto;
}
</style>