
import axios from 'axios'
import router from '../router/index'
import {Message, MessageBox} from "element-ui";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const tip = msg => {
  Message({
    message: msg,
    duration: 3000
  });
}

const toLogin = () => {
  router.push("login").then(r => {
    console.log(r)
  }).catch(() => {});
}
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://127.0.0.1:9898',
  baseURL: process.env.VUE_APP_BASE_DOMAIN,
  // 超时
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config => {
  // 登录流程控制中，根据本地是否存在token判断用户的登录情况
  // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
  // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
  // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
  const token = localStorage.getItem('access_token');
  token && (config.headers.access_token = token);

  return config
}, error => {
  console.error('request error: ' + error)
  return Promise.reject(error)
})

//响应拦截器
service.interceptors.response.use(response => {
  // 这里处理之后到页面的200响应数据为resp
  const resp = response.data;
  console.log('resp: ' + resp.message)
  if (response.status !== 200) {
    console.error(response.status + response.toString())
    errorHandle(response.status , response.toString())
    // return Promise.reject(response)
  } else {
    if (resp.code === 401 || resp.code === 400){
      errorHandle(resp.code, resp.message)
    }
    return resp
  }
}, error => {
  console.error('resp error: ' + error.toString())
  errorHandle(error.status, error.toString())
  return Promise.reject(error)
});

const  errorHandle = (status, other) => {
  switch (status) {
    case 403:
      console.log(403)
      toLogin();
      break;
    case 401:
      console.log(401)
      localStorage.removeItem('access_token');
      MessageBox({
        message: 'Token expired，login again please。' + other,
        callback: () => {
          toLogin();
        }
      }).catch(() => {
      })
      break;
    case 404:
      console.log(404)
      tip('Resource not found！');
      break;
    case 400:
      console.log(400)
      tip('Bad Request ! Please check request data: ' + other);
      break;
    case 500:
      console.log(500)
      router.push({path: "500"}).then(() => {})
      tip("Internal Error: " + other)
      break;
  }
}

export default service