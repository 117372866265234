<template>
<div id="role-auth-container">
  <el-row :gutter="20" class="el-row" type="flex">
    <div class="grid-content bg-purple" id="role-bar">
      <h1 class="el-icon-s-check">角色列表</h1>

        <div class="role-list" v-for="(role, index) in roles" :key="index">
          <el-radio-group v-model="roleAndPermsForm.role_id" @change="handleRoleClick">
            <el-radio v-model="roleAndPermsForm.role_id" :label="role.id"><br></el-radio>
          </el-radio-group>
          <span id="single-role">
            {{ role.name }}
          </span><br><br>
        </div>

    </div>
    <div class="grid-content bg-purple" id="permission-bar">
          <h1 class="el-icon-lock">权限列表</h1>
          <el-tree
              class="perms-tree"
              :data="perms"
              show-checkbox
              highlight-current
              default-expand-all
              :check-strictly="checkAbout"
              :ref="'tree'"
              node-key="id"
              :props="defaultProps"
              :indent="30"
              :render-after-expand="false"
              @check-change="handlePermsClick"
              :default-checked-keys="defaultChecked"
            >
            </el-tree>
    </div>
    <div id="buttons">
      <span>
        <el-button type="primary" @click="toAddRole" round>添加角色</el-button><br><br>
      </span>
      <span>
        <el-button type="primary" @click="toAddPerm" round>添加权限</el-button><br><br>
      </span>
      <span>
        <el-button type="primary" @click="roleAuth" round>提交授权</el-button>
      </span>
    </div>
  </el-row>
</div>
</template>

<script>
import {addRolePerms, getRolePerms, getRolesOrPerms} from "../../api/user";

export default {
  name: "RoleAuthorize",
  data() {
    const validateRoleId = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请选择需要授权角色'))
       } else {
         callback()
       }
     }
    return {
      roles: [],
      perms: [],
      roleAndPermsForm: {
        role_id: 1,
        perm_ids: []
      },
      roleAndPermsRules: {
        role_id: [
             { required: true, message: '请选择需要授权角色', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateRoleId }],
      },
      treeLoading: false,
      checkAbout: false,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
        disabled: this.isDisabled
      },
      defaultChecked: []
    }
  },

  mounted() {
    this.loadRoles();
    this.loadPerms();
    this.handleRoleClick(1)
  },

  methods: {
    async loadRoles(){
      await getRolesOrPerms({model: "Role"}).then(res => {
        console.log(res)
        this.roles = res.data;
      }).catch(err => {
        console.log(err);
      })
    },
    async loadPerms(){
      await getRolesOrPerms({model: "Authority"}).then(res => {
        console.log(res)
        this.perms = res.data;
      }).catch(err => {
        console.log(err);
      })
    },
    handleRoleClick(role_id) {
      const form = {
        model: "RolePerms",
        id: role_id
      }
      getRolePerms(form).then(res => {
        if (res.code !== 200){
          return this.$message.error("Get Role Perms Failed:" + res.message)
        }
        this.defaultChecked = res.data
        this.$refs.tree.setCheckedKeys(this.defaultChecked)
      })
    },

    handlePermsClick() {
    },
    roleAuth() {
      this.roleAndPermsForm.perm_ids = this.$refs.tree.getCheckedKeys()
      console.log(this.roleAndPermsForm)
      addRolePerms(this.roleAndPermsForm).then(res => {
        if (res.code !== 200) {
          return this.$message.error("Role Auth Failed:" + res.message)
        }
        this.$message.success("Role Auth Success:" + res.message)
        this.$router.push("role-list").then(() => {})
      }).catch(err => {
        this.$message.error("Role Auth Failed:" + err.message)
      })
    },
    toAddRole(){
      this.$router.push("add-role").then(() => {})
    },
    toAddPerm(){
      this.$router.push("add-permission").then(() => {})
    }
  }
}
</script>

<style scoped>
#role-bar, #permission-bar {
  color: lightgreen;
  border: solid;
  width: 40%;
  position: center;
}

#buttons {
  padding-top: 25%;
}

.role-list, .perms-tree {
  color: lime;
  background: transparent;
  text-align: left;
}
</style>