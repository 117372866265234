<template>
<div title="用户信息" class="user-info-box">
  <el-form id="user-form" :model="userInfo" ref="userInfo">
    <h1 class="h1">个人信息栏</h1>
      <el-form-item label="用户名:" class="color-item">
        <el-input v-model="userInfo.user_info.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="姓名:" class="color-item">
        <el-input v-model="userInfo.user_info.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="手机号:" class="color-item">
        <el-input v-model="userInfo.user_info.phone" disabled></el-input>
      </el-form-item>
      <el-form-item label="邮箱:" class="color-item">
        <el-input v-model="userInfo.user_info.email" disabled></el-input>
      </el-form-item>
      <el-form-item label="年龄:" class="color-item">
        <el-input v-model="userInfo.user_info.age"></el-input>
      </el-form-item>
      <el-form-item label="生日:" class="color-item">
        <el-input v-model="userInfo.user_info.birthday"></el-input>
      </el-form-item>
      <el-form-item label="性别:" class="color-item">
        <el-input v-model="userInfo.user_info.gender"></el-input>
      </el-form-item>
      <el-form-item label="地址:" class="color-item">
        <el-input v-model="userInfo.user_info.address"></el-input>
      </el-form-item>
    <el-form-item label="用户状态:" class="color-item">
        <el-select v-model="userInfo.user_info.status" style="width: 100%"
                   placeholder="请选择状态" clearable>
          <el-option v-for="(item, index) in userStatus"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    <el-form-item label="备注:" class="color-item">
        <el-input v-model="userInfo.user_info.remark"></el-input>
      </el-form-item>
    <h1 class="h1">角色信息栏</h1>
      <el-form-item label="角色名称:" class="color-item">
        <el-input v-model="userInfo.role_info.role_name"></el-input>
      </el-form-item>
      <el-form-item label="角色编码:" class="color-item">
        <el-input v-model="userInfo.role_info.role_code"></el-input>
      </el-form-item>
    <h1 class="h1">工作信息栏</h1>
      <el-form-item label="公司:" class="color-item">
        <el-input v-model="userInfo.job_info.company"></el-input>
      </el-form-item>
      <el-form-item label="公司地址:" class="color-item">
        <el-input v-model="userInfo.job_info.group_address"></el-input>
      </el-form-item>
      <el-form-item label="部门:" class="color-item">
        <el-input v-model="userInfo.job_info.department"></el-input>
      </el-form-item>
      <el-form-item label="职位:" class="color-item">
        <el-input v-model="userInfo.job_info.position"></el-input>
      </el-form-item>
      <el-form-item label="职位级别:" class="color-item">
        <el-select v-model="userInfo.job_info.position_level" style="width: 100%"
                   placeholder="请选择职级" clearable>
          <el-option v-for="(item, index) in positionLevel"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工编号:" class="color-item">
        <el-input v-model="userInfo.job_info.job_num"></el-input>
      </el-form-item>
      <el-form-item label="员工状态:" class="color-item">
        <el-select v-model="userInfo.job_info.job_status" style="width: 100%"
                   placeholder="请选择员工状态" clearable>
          <el-option v-for="(item, index) in jobStatus"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
<!--        <el-input v-model="userInfo.job_info.job_status" disabled></el-input>-->
      </el-form-item>
    <el-form-item class="btns">
          <el-button class="btn-confirm" size="normal" type="primary" @click="handleUpdate">保存</el-button>
          <el-button class="btn-cancel" size="normal" type="warning" @click="handleClose">取消</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import {getUserInfo, updateUserInfo} from "../../api/user";

export default {
  name: "UserInfo",
  data () {
    const userStatus = [
      {id: 0, name: "已禁用"},
      {id: 1, name: "已启用"},
    ]
    const jobStatus = [
      {id: 0, name: "离职"},
      {id: 1, name: "在职"},
    ]
    const positionLevel = [
      {id: 1, name: "初级(primary)"},
      {id: 2, name: "中级(middle)"},
      {id: 3, name: "高级(senior)"},
      {id: 4, name: "资深(professional)"},
    ]
    return {
      userStatus,
      jobStatus,
      positionLevel,
      rawData: {},
      diffData:{},
      userInfo: {
        user_info: {
          id: "",
          username: "",
          name: "",
          phone: "",
          email: "",
          age: "",
          gender: "",
          birthday: "",
          address: "",
          status: "",
          remark: "",
        }, 
        role_info: {
          role_name: "",
          role_code: ""
        },
        job_info: {
          company: "",
          group_address: "",
          department: "",
          position: "",
          position_level: "",
          job_num: "",
          job_status: "",
        }
      },

    }
  },

  mounted(){
    this.queryUserInfo();
  },

  methods: {
    async queryUserInfo(){
      const form = {id: this.$route.query.id}
      await getUserInfo(form).then(res => {
        console.log(res)
        if (res.code !== 200){
          return this.$message.error(res.message)
        }
        this.rawData = {...res.data}   // clone一份数据当原始数据

        this.userInfo = res.data  // 会做修改的数据
        this.userInfo.user_info.id = form.id
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    handleClose(){
      this.$router.go(-1);
    },
    async handleUpdate(){
      this.getDiffData()
      const form = {
        user_info: this.userInfo.user_info,
        role_info: this.userInfo.role_info,
        job_info: this.userInfo.job_info,
      }
      this.$confirm(`确定更新?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
      await updateUserInfo(form).then(res => {
        if (res.code !== 200){
          return this.$message.error("Modify user info failed: " + res.message)
        }
        this.$message.success('Message: ' + res.message)
        this.$forceUpdate()
      }).catch(err => {
        return this.$message.error("Exception: " + err)
      })
      }).catch(err => {
        return this.$message.info(err)
      })
    },
    getDiffData(){
      Object.keys(this.rawData).forEach(item => {
        if (this.rawData[item] !== this.userInfo[item]) {
          this.diffData[item] = this.userInfo[item]
        }
      })
    },
  },
}
</script>

<style scoped>

#user-form {
  width: 50%;
  margin: 0 auto;
}

.color-item .el-form-item__label {
  margin-left: -20%;
  width: auto;
  color: lightgreen;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn-cancel {
  margin-left: 100px;
  width: 70px;
  height: 40px;
}

.h1 {
  color: greenyellow;
}

</style>