import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function phoneLogin(data) {
  return request({
    url: '/user/phone-login',
    method: 'post',
    data
  })
}

export function getCode() {
  return request({
    responseType: 'arraybuffer',
    url: '/user/get-captcha?' + Math.random(),
    method: 'get',
  })
}

export function sendSmsCode(data) {
  return request({
    url: '/user/send-verify-code',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}

export function changePwd(data) {
  return request({
    url: '/user/change-pwd',
    method: 'patch',
    data
  })
}

export function resetPwd(data) {
  return request({
    url: '/user/reset-pwd',
    method: 'post',
    data
  })
}


export function getMyInfo() {
  return request({
    url: '/user/get-my-info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'get',
  })
}

export function searchUsers(params) {
  return request({
    url: '/user/user-list',
    method: 'get',
    params
  })
}

export function getUserJobInfo() {
  return request({
    url: '/user/get-user-job-info',
    method: 'get',
  })
}

export function getRoles(params) {
  return request({
    url: '/user/role-list',
    method: 'get',
    params
  })
}

export function addRole(data) {
  return request({
    url: '/user/add-role',
    method: 'post',
    data
  })
}

export function getRoleInfo(params) {
  return request({
    url: '/user/get-role-info',
    method: 'get',
    params
  })
}

export function updateRole(data) {
  return request({
    url: '/user/modify-role',
    method: 'PUT',
    data
  })
}

export function deleteRole(data) {
  return request({
    url: '/user/delete-role',
    method: 'delete',
    data
  })
}

export function addUserRole(data) {
  return request({
    url: '/user/bind-user-role',
    method: 'post',
    data
  })
}

export function getAuthorities(params) {
  return request({
    url: '/user/authority-list',
    method: 'get',
    params
  })
}

export function getParentAuthorities() {
  return request({
    url: '/user/parent-authorities',
    method: 'get',
  })
}

export function getAuthorityInfo(params) {
  return request({
    url: '/user/get-authority-info',
    method: 'get',
    params
  })
}

export function addAuthority(data) {
  return request({
    url: '/user/add-authority',
    method: 'post',
    data
  })
}

export function updateAuthority(data) {
  return request({
    url: '/user/modify-authority',
    method: 'post',
    data
  })
}

export function deleteAuthority(data) {
  return request({
    url: '/user/delete-authority',
    method: 'delete',
    data
  })
}

export function updateMyInfo(data) {
  return request({
    url: '/user/update-my-info',
    method: 'put',
    data
  })
}

export function getRolesOrPerms(params) {
  return request({
    url: '/user/roles-or-perms',
    method: 'get',
    params
  })
}

export function addRolePerms(data){
  return request({
    url: '/user/add-role-authority',
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/user/add-user',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  return request({
    url: '/user/get-user-info',
    method: 'get',
    params: data
  })
}

export function updateUserInfo(data) {
  return request({
    url: '/user/modify-user-info',
    method: 'post',
    data
  })
}

export function deleteUser(data) {
  return request({
    url: '/user/delete-user',
    method: 'delete',
    data
  })
}

export function getUserMenus() {
  return request({
    url: '/user/get-user-role-perms',
    method: 'get',
  })
}

export function getRolePerms(params) {
  return request({
    url: '/user/role-perms',
    method: 'get',
    params
  })
}