import request from '@/utils/request'

export function uploadFile(data) {
  return request({
    url: '/system/upload-file',
    method: 'post',
    timeout: 20000,
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8'
    },
    data
  })
}

export function sendEmail(data) {
  return request({
    url: '/system/send-email',
    method: 'post',
    data
  })
}