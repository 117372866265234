<template>
<div class="container-500">
  <div class="img-500"></div>
</div>
</template>

<script>
export default {
  name: "InternalError"
}
</script>

<style scoped>
.container-500 {
  display: table;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.img-500 {
  background: url("../assets/500.png");
  display: table-cell;
  height: 100%;
  width: 50%;
  vertical-align: middle;
  padding: 0 30px;
}
</style>