<template>
<div title="角色信息" class="role-info-box">
  <el-form id="role-form" :model="roleInfo" ref="roleInfo">
    <h1 class="h1">角色信息栏</h1>
      <el-form-item label="角色名:" class="color-item">
        <el-input v-model="roleInfo.name"></el-input>
      </el-form-item>
      <el-form-item label="角色编码:" class="color-item">
        <el-input v-model="roleInfo.code"></el-input>
      </el-form-item>
      <el-form-item label="编辑人:" class="color-item">
        <el-input v-model="roleInfo.creator" disabled></el-input>
      </el-form-item>
    <el-form-item label="角色状态:" class="color-item">
        <el-select v-model="roleInfo.status" style="width: 100%"
                   placeholder="请选择状态" clearable>
          <el-option v-for="(item, index) in roleStatus"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    <el-form-item class="btns">
          <el-button class="btn-confirm" size="normal" type="primary" @click="handleUpdate">保存</el-button>
          <el-button class="btn-cancel" size="normal" type="warning" @click="handleClose">取消</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import Cookies from "js-cookie";
import {getRoleInfo, updateRole} from "../../api/user";

export default {
  name: "RoleInfo",
  data () {
    const roleStatus = [
      {id: 0, name: "禁用"},
      {id: 1, name: "启用"},
    ]

    return {
      roleStatus,
      rawData: {},
      diffData:{},
      roleInfo: {
          id: "",
          name: "",
          code: "",
          creator: "",
          status: "",
        },

    }
  },

  mounted(){
    this.queryRoleInfo();
  },

  methods: {
    async queryRoleInfo(){
      const form = {id: this.$route.query.id}
      await getRoleInfo(form).then(res => {
        console.log(res)
        if (res.code !== 200){
          return this.$message.error(res.message)
        }
        this.rawData = {...res.data}   // clone一份数据当原始数据

        this.roleInfo = res.data  // 会做修改的数据
        this.roleInfo.id = form.id
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    handleClose(){
      this.$router.push("role-list")
    },
    async handleUpdate(){
      this.roleInfo.creator = Cookies.get("user_id")

      this.$confirm(`确定更新?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
      await updateRole(this.roleInfo).then(res => {
        if (res.code !== 200){
          return this.$message.error("Modify role info failed: " + res.message)
        }
        this.$message.success('Message: ' + res.message)
        this.$forceUpdate()
      }).catch(err => {
        return this.$message.error("Exception: " + err)
      })
      }).catch(err => {
        return this.$message.info(err)
      })
    },
    getDiffData(){
      Object.keys(this.rawData).forEach(item => {
        if (this.rawData[item] !== this.roleInfo[item]) {
          this.diffData[item] = this.roleInfo[item]
        }
      })
    },
  },
}
</script>

<style scoped>

#role-form {
  width: 50%;
  margin: 0 auto;
}

.color-item .el-form-item__label {
  margin-left: -20%;
  width: auto;
  color: lightgreen;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn-cancel {
  margin-left: 100px;
  width: 70px;
  height: 40px;
}

.h1 {
  color: greenyellow;
}

</style>