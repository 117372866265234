<template>
  <div class="sms-code-login_container">
    <div class="sms-code-login_box">
      <div class="avatar_box">
                <img class="login-logo" src="../../assets/flash-z.jpg" alt=""/>
      </div>
      <!-- 登录表单区域 -->
      <el-form class="sms-code-login_form" :model="loginForm" :rules="loginRules" ref="loginFormRef">
        <p class="sms-code-login-head">手机验证码登录</p>
        <!-- 用户名 -->
        <el-form-item label="手机号" class="color-item" prop="phone" id="phone" :rules="loginRules.phone">
          <el-input type="text" v-model="loginForm.phone" placeholder="手机号"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item label="验证码" class="color-item" prop="sms_code" id="sms_code" :rules="loginRules.sms_code">
            <el-input
                  type="text"
                  ref="code"
                  prefix-icon="el-icon-document-checked"
                  clearable
                  placeholder="手机验证码"
                  v-model="loginForm.sms_code"
                  keyup.enter.native="handleLogin"
              ></el-input>
          <div class="divSmsCode">
            <el-button
                @click="sendSMSCode"
                :class="{'disabled-style':getCodeBtnDisable}"
                :disabled="getCodeBtnDisable">{{codeBtnWord}}</el-button>
          </div>
        </el-form-item>

<!--        </div>-->
        <!--按钮区域-->
        <el-form-item class="btns">
          <el-button class="btn-login" type="primary" :disabled="isLogin" @click="handleLogin">登录</el-button>
          <el-button class="btn-reset" type="warning" @click="resetForm">重置</el-button>
        </el-form-item>
        <a href="#">
          <span class="to-register" @click="toRegister">没有账号？前往注册</span>
        </a>
      </el-form>
    </div>
  </div>
</template>

<script>
import backgroundImage from "../../assets/Login.jpg";
import {phoneLogin, sendSmsCode} from "../../api/user";
import Cookies from "js-cookie";

export default {
  name: "SMSCodeLogin",
  data() {
     const validatePhone = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入11位手机号'))
       } else {
         if (value.length !== 11) {
           callback(new Error('请输入小于11位手机号'))
         } else {
           callback()
         }
       }
     }
     const validateSmsCode = (rule, value, callback) => {
       if (value.length !== 6) {
         callback(new Error('请输入6位手机认证码'))
       } else {
         callback()
       }
     }
     return {
       backgroundImage,
       // logo,
       bg: {
         background: `url(${backgroundImage})`,
         backgroundSize: '100% 100%'
       },
       loginForm: {
         sms_code: '',
         phone: '',
       },
       loginRules: {
         sms_code: [
           {required: true, message: '请输入验证码', trigger: 'blur'},
           {min: 6, max: 6, message: '长度为6个数字', trigger: 'blur'},
           {required: true, trigger: 'blur', validator: validateSmsCode}],
         phone: [
           {required: true, message: '请输入手机号', trigger: 'blur'},
           {min: 11, max: 11, message: '长度在 11位数字', trigger: 'blur'},
           {required: true, trigger: 'blur', validator: validatePhone}],
       },
       codeBtnWord: '获取验证码', // 获取验证码按钮文字
       waitTime:61, // 获取验证码按钮失效时间
     }
  },

  computed: {
    phoneStyle() {
      let reg = /^1[3456789]\d{9}$/
      return reg.test(this.loginForm.phone);
    },
    getCodeBtnDisable: {
      get () {
        if (this.waitTime === 61) {
          return !this.loginForm.phone;
        }
        return true
      },
      set () {}
    },

    isLogin() {
        return !this.loginForm.phone || !this.loginForm.sms_code
    }
  },

  methods: {
       async handleLogin() {

         let form = {
           phone: this.loginForm.phone,
           sms_code: this.loginForm.sms_code
         };
         await this.$refs['loginFormRef'].validate((valid) => {
           if (valid) {
             // 登录处理
             phoneLogin(form).then(res => {
               console.log('res:', res)
               if (res.code !== 200) {
                 return this.$message.error('Login Failed: ' + res.message)
               }
               this.$message.success('Message: ' + res.message)
               // 存放登录令牌
               localStorage.setItem("access_token", res.data.access_token) // cookie中设置用户名
               Cookies.set('name', res.data.name)
               Cookies.set('username', res.data.username)
               Cookies.set('user_id', res.data.user_id)

               this.$router.push('home').catch(() => {
               })

             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
                 return this.$message.error("Please Input Valid Request Data: " + ex.message)
               }
             })
           } else {
             this.$refs.loginFormRef.resetFields();
           }
         })

       },
       // 跳转至注册页面
       toRegister() {
         this.$router.push('register');
       },

       // 重置输入框内容
       resetForm() {
         this.$refs.loginFormRef.resetFields();
       },

       // 获取手机验证码
       async sendSMSCode() {
         await sendSmsCode({phone: this.loginForm.phone}).then(res => {
           console.log(res)
           if (res.code !== 200) {
             console.error(res.message)
             return this.$message.error('Send SMS Code Failed: ' + res.message)
               }
           this.$message.success('Message: ' + res.message)
         }).catch(ex => {
           console.error(ex);
         })
         // 因为下面用到了定时器，需要保存this指向
        let that = this
        that.waitTime--
        that.getCodeBtnDisable = true
        this.codeBtnWord = `${this.waitTime}s 后重新获取`
        let timer = setInterval(function(){
            if(that.waitTime>1){
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
            }else{
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
            }
        },1000)
       },
       handleError() {
         console.log(this)
       },
     }
}
</script>

<style scoped>
.sms-code-login_container {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('../../assets/Login.jpg');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%
}

.sms-code-login_box {
  width: 40%;
  height: 600px;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
}

.color-item .el-form-item__label {
  margin-left: -30%;
  width: 30%;
  color: lightgreen;
}

.sms-code-login_form {
  text-align:center;
  margin-top: 20%;
}

#phone, #password {
  width: 50%;
  margin: 25px auto;
}

#sms_code {
  width: 50%;
  height: 40px;
  padding: 0;
  margin: 25px auto;
}

.divSmsCode {
  position: absolute;
  top: 0;
  right: -60px;
  z-index: 5;
  width: 60px;
  height: 40px;
  margin: 0;
}
/*.btn-login {*/
/*  width: 20%;*/
/*  position: inherit;*/
/*}*/

.to-register {
  float: none;
  margin-left: 0;
}
.el-button.disabled-style {
    background-color: #EEEEEE;
    color: #CCCCCC;
}

.sms-code-login-head {
  color: lightgreen;
}

.btns {
  display: flex;
  justify-content: center;
}

.login-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #fff;
  img {
      width: 10%;
      height: 10%;
      border-radius: 50%;
      background-color: #eee;
  }
}

</style>