<template>
<div title="添加用户" class="add-user-container">
  <div class="add_user_box">
      <el-form class="user_add_form" :model="userAddForm" :rules="userAddRules" ref="userAddFormRef">
        <p class="user-add-head">添加用户</p>
        <!-- 用户名 -->
        <el-form-item label="用户名" class="color-item" prop="username" id="username" :rules="userAddRules.username">
          <el-input type="text" v-model="userAddForm.username" placeholder="用户名"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 姓名 -->
        <el-form-item label="姓名" class="color-item" prop="name" id="name" :rules="userAddRules.name">
          <el-input type="text" v-model="userAddForm.name" placeholder="姓名"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item label="手机号" class="color-item" prop="phone" id="phone" :rules="userAddRules.phone">
          <el-input type="number" placeholder="手机号" :maxlength="11"
                    v-model="userAddForm.phone" prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
        <!-- 密码框 -->
        <el-form-item label="密码" class="color-item" prop="password" id="password" :rules="userAddRules.password">
          <el-input type="password" v-model="userAddForm.password" placeholder="密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" class="color-item" prop="email" id="email" :rules="userAddRules.email">
          <el-input type="text" v-model="userAddForm.email" placeholder="邮箱"
                    prefix-icon="el-icon-message"></el-input>
        </el-form-item>
        <el-form-item label="角色" class="color-item" prop="role" id="role">
        <el-select id="role-select" v-model="userRoleForm.role_name"
                   filterable
                   style="width: 100%"
                   placeholder="请选择角色" clearable>
          <el-option
              v-for="(item, index) in userRoles"
              :key="index"
              :label="item.name"
              :value="item.name"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" :disabled="isAdd" @click="handleAddUser">添加</el-button>
          <el-button @click="closeAddUser">取消</el-button>
        </el-form-item>
      </el-form>
  </div>
</div>
</template>

<script>
import {addUser, addUserRole, getRolesOrPerms} from "../../api/user";

export default {
  name: "AddUser",
  data() {
      const validateUsername = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入用户名'))
       } else {
         if (value.length > 20 || value.length < 3) {
           callback(new Error('请输入小于3-20位用户名'))
         } else {
           callback()
         }
       }
     }
      const validateName = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输姓名'))
       } else if (value.length > 20) {
         callback(new Error('请输入长度小于20位的姓名'))
       } else {
         callback()
       }
     }
      const validatePassword = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入密码'))
       } else if (value.length < 8 ||value.length > 20) {
         callback(new Error('请输入8-20位密码'))
       } else {
         callback()
       }
     }
      const validatePhone = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输手机号'))
       } else if (value.length !== 11) {
         callback(new Error('请输手11位数机号'))
      } else {
         callback()
       }
     }
      const validateEmail = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入正确格式的邮箱'))
       } else {
         callback()
       }
     }
    return {
      userRoles: [],
      userAddForm: {
        username: "",
        password: "",
        name: "",
        phone: "",
        email: "",
      },
      userRoleForm: {
        username: "",
        role_name: "",
      },
      userAddRules: {
         username: [
             { required: true, message: '请输入用户名', trigger: 'blur' },
             { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateUsername }],
         name: [
             { required: true, message: '请输入姓名', trigger: 'blur' },
             { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateName }],
         phone: [
             { required: true, message: '请输入手机号', trigger: 'blur' },
             { min: 11, max: 11, message: '长度为11个数字', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validatePhone }],
         password: [
             { required: true, message: '请输入密码', trigger: 'blur' },
             { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validatePassword }],
         email:  [
             { required: true, message: '请输入邮箱', trigger: 'blur' },
             { min: 6, max: 32, message: '长度为8-32个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateEmail }],
         role_name:  [
             { required: true, message: '请选择角色', trigger: 'blur' }],
       },
    }
  },
  mounted() {
    this.loadRoles();
  },

  computed: {
    isAdd() {
        return !this.userAddForm.username || !this.userAddForm.password ||
            !this.userAddForm.name || !this.userAddForm.phone || !this.userAddForm.email
    }
  },

  methods: {
    async handleAddUser(){
      const form = {
        username: this.userAddForm.username,
        password: this.$md5(this.userAddForm.password),
        name: this.userAddForm.name,
        phone: this.userAddForm.phone,
        email: this.userAddForm.email,
      }
      const user_role_form = {
        username: this.userAddForm.username,
        role_name: this.userRoleForm.role_name,
      }
      await this.$refs['userAddFormRef'].validate((valid) => {
           if (valid) {
             addUser(form).then(res => {
             console.log('res:', res)
             if (res.code !== 200) {
               return this.$message.error('Add User Failed: ' + res.message)
             }
             this.$message.success('Message: ' + res.message);
             addUserRole(user_role_form).then(resp => {
               console.log('resp:', resp)
               if (res.code !== 200) {
                 return this.$message.error('Bind User Role Failed: ' + res.message)
               }
                this.$message.success('Message: ' + res.message);
                this.$router.push('home').catch(() => {});
               }).catch(err => {
                 console.error(err)
               return this.$message.error('Bind User Role Error: ' + err.message)
             });
             this.$router.push('home').catch(() => {});
             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + ex.message)
             }
             })
           } else {
             // this.$refs.userAddFormRef.resetFields();
             return this.$message.error("Please Input Valid Request Data!")
           }
         })
    },
    closeAddUser(){
      this.$refs.userAddFormRef.resetFields();
      this.$router.go(-1);
    },
    async loadRoles(){
      await getRolesOrPerms({model: "Role"}).then(res => {
        console.log(res)
        this.userRoles = res.data;
      }).catch(err => {
        console.log(err);
      })
    },
  },
}
</script>

<style scoped>
.add_user_box {
  width: 40%;
  height: 700px;
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  border-radius: 20px;
  margin-top: 20px;
}

.user_add_form {
  text-align:center;
  margin-top: 10%;
}

.user-add-head {
  color: #fff;
  font-size: 20px;
}

#username, #name, #password, #phone, #email, #role {
  width: 50%;
  margin: 25px auto;
}

</style>