<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
                <img class="login-logo" src="../../assets/flash-z.jpg" alt=""/>
      </div>
      <!-- 登录表单区域 -->
      <el-form class="login_form" :model="loginForm" :rules="loginRules" ref="loginFormRef">
        <p class="login-head">用户登录</p>
        <!-- 用户名 -->
        <el-form-item label="用户名" class="color-item" prop="username" id="username" :rules="loginRules.username">
          <el-input type="text" v-model="loginForm.username" placeholder="用户名/邮箱/手机号"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" class="color-item" prop="password" id="password" :rules="loginRules.password">
          <el-input type="password" v-model="loginForm.password" placeholder="密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 验证码 -->
          <el-form-item label="验证码" class="color-item" prop="verify_code" id="verify_code" :rules="loginRules.verify_code">
            <el-input
                  type="text"
                  ref="code"
                  prefix-icon="el-icon-document-checked"
                  clearable
                  placeholder="验证码"
                  v-model="loginForm.verify_code"
                  keyup.enter.native="handleLogin"
              ></el-input>
            <div class="divVerifyCode">
            <img :src="codeImg" @click="loadCode" id="imgVerifyCode" title="点击刷新" alt="点击刷新" />
            </div>
          </el-form-item>

<!--        </div>-->
        <!--按钮区域-->
        <el-form-item class="btns">
          <el-button class="btn-login" type="primary" :disabled="isLogin" @click="handleLogin">登录</el-button>
          <el-button class="btn-reset" type="warning" @click="resetForm">重置</el-button>
        </el-form-item>
        <a href="#">
          <span class="to-register" @click="toRegister">没有账号？前往注册</span>
        </a>
        <a href="#">
          <span class="to-phone-login" @click="toPhoneLogin">验证码登录</span>
        </a>
        <a href="#">
          <span class="to-find-pwd" @click="toFindPwd">忘记密码？</span>
        </a>
        <br><br><br><br>
        <div class="third-login">
          <span id="third-login-text">第三方登录：</span>
          <el-image id="wx-login-btn" :src=wechatLogo @click=toWechatLogin></el-image>
          <el-image id="qq-login-btn" :src=QQLogo @click=toQQLogin></el-image>
          <el-image id="wb-login-btn" :src=weiboLogo @click=toWeiboLogin></el-image>
        </div>
      </el-form>
      <div class="footer">
        <p class="copyright"><a href="https://beian.miit.gov.cn/">备案号：{{ recordNumber }}</a></p>
    </div>
    </div>
  </div>

</template>
<script>
import Cookies from 'js-cookie'
import { getCode, login } from '../../api/user'
import loginLogo from "../../assets/Login.jpg";
import wechatLogo from "../../assets/wechat.png"
import QQLogo from "../../assets/QQ.png"
import weiboLogo from "../../assets/weibo.png"
 export default {
   name: 'Login',
   data() {
     const validateUsername = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入登录名'))
       } else {
         if (value.length > 20) {
           callback(new Error('请输入小于20位登录名'))
         } else {
           callback()
         }
       }
     }
     const validatePassword = (rule, value, callback) => {
       if (value.length < 6 ||value.length > 20) {
         callback(new Error('请输入6-20位密码'))
       } else {
         callback()
       }
     }
     const validateVerifyCode = (rule, value, callback) => {
       if (value.length !== 6) {
         callback(new Error('请输入右侧6位认证码'))
       } else {
         callback()
       }
     }
     return {
       loginLogo,
       wechatLogo,
       QQLogo,
       weiboLogo,
       recordNumber: '沪ICP备2022006609号-1',
       codeImg: '',
       bg:{
         background:`url(${loginLogo})`,
         backgroundSize:'100% 100%'
       },
       loginForm: {
         verify_code:'',
         username: '',
         password: ''
       },
       loginRules: {
         verify_code:  [
             { required: true, message: '请输入验证码', trigger: 'blur' },
             { min: 6, max: 6, message: '长度为6个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateVerifyCode }],
         username: [
             { required: true, message: '请输入用户名', trigger: 'blur' },
             { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateUsername }],
         password: [
             { required: true, message: '请输入密码', trigger: 'blur' },
             { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validatePassword }]
       },
       capsTooltip: false,
       loading: false,
       showDialog: false,
       redirect: undefined,
       otherQuery: {}
     }
   },

   mounted() {
     this.loadCode();
   },

   computed: {
    isLogin() {
        return !this.loginForm.username || !this.loginForm.password || !this.loginForm.verify_code
    }
  },

   methods: {
     async handleLogin() {

         let form = {
           username: this.loginForm.username,
           password: this.$md5(this.loginForm.password),
           verify_code: this.loginForm.verify_code
         };
         await this.$refs['loginFormRef'].validate((valid) => {
           if (valid) {
             // 登录处理
             login(form).then(res => {
             console.log('res:', res)
             if (res.code !== 200) {
               this.loadCode()
               return this.$message.error('Login Failed: ' + res.message)
             }
             this.$message.success('Message: ' + res.message)
             // 存放登录令牌
             localStorage.setItem("access_token", res.data.access_token) // cookie中设置用户名
             Cookies.set('name', res.data.name)
             Cookies.set('username', res.data.username)
             Cookies.set('user_id', res.data.user_id)

             this.$router.push('home').catch(() => {})

             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + ex.message)
             }
             })
           } else {
             this.$refs.loginFormRef.resetFields();
           }
         })

     },
     // 跳转至注册页面
     toRegister(){
       this.$router.push('register');
     },
     // 找回密码
     toFindPwd(){
      this.$router.push('reset-pwd');
     },

     // 手机登录
     toPhoneLogin(){
      this.$router.push('phone-login');
     },

     toWechatLogin(){
       this.$router.push('wechat-login')
     },

     toQQLogin(){
       this.$router.push('qq-login')
     },

     toWeiboLogin(){
       this.$router.push('weibo-login')
     },

     // 重置输入框内容
     resetForm(){
       this.$refs.loginFormRef.resetFields();
     },

     // 获取验证码
     async loadCode() {
       let _this = this;
       await getCode().then(res => {
        console.log(res)
        return 'data:image/png;base64,' + btoa(
              new Uint8Array(res)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
      }).then(data => {
        console.log(data)
        _this.codeImg = data;
       }).catch(ex => {
         console.error(ex);
       })
     },
     handleError(){
       console.log(this)
     },
   }
 }
</script>

<style>
.login_container {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('../../assets/Login.jpg');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%
}

.login_box {
  width: 40%;
  height: 600px;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
}

.color-item .el-form-item__label {
  margin-left: -30%;
  width: 30%;
  color: lightgreen;
}

.login_form {
  text-align:center;
  margin-top: 20%;
}

.divVerifyCode {
  position: absolute;
  top: 0;
  right: -85px;
  z-index: 5;
  height: 40px;
  margin: 0;
}

#imgVerifyCode {
  float: left;
  height: 40px;
  width: 85px;
  border-radius: 5px;
  cursor: pointer
}

#username, #password {
  width: 50%;
  margin: 25px auto;
}

#verify_code {
  width: 50%;
  height: 40px;
  padding: 0;
  margin: 25px auto;
}

.third-login {
  width: 100%;
  color: lightgreen;
}

#third-login-text {
  height: 30px;
  display: block;
  margin: 1px 1px;
  text-align: center;
  line-height: 20px;
}

#wx-login-btn, #qq-login-btn, #wb-login-btn {
  width: 30px;
  height: 30px;
  padding: 0 0;
  border: 5px solid rgba(255,255,255,0);
}

.el-icon-wechat {
  background: url("../../assets/wechat.png") no-repeat;
  font-size: 28px;
  background-size: cover;
}
.el-icon-wechat:before {
  content: "口";
  font-size: 16px;
  color:rgba(255,255,255,0);
   }

/*.btn-login {*/
/*  width: 20%;*/
/*  position: inherit;*/
/*}*/

.to-register {
  /*color: aliceblue;*/
  float: left;
  margin-left: 15%;
}

.to-find-pwd {
  /*color: aliceblue;*/
  float: right;
  margin-right: 15%;
}

.login-head {
  color: lightgreen
}

.btns {
  display: flex;
  justify-content: center;
}

.login-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #fff;
  img {
      width: 10%;
      height: 10%;
      border-radius: 50%;
      background-color: #eee;
  }
}
.copyright {
  color: lightgreen;
}

</style>