<template>
    <div>
      <el-container class="el-container">
          <el-aside id="left-side" :width="isCollapse?'50px':'250px'">
            <div class="toggle-button" @click="toggleCollapse">
            <i class="el-icon-menu"></i>
            </div>
            <el-menu  :collapse="isCollapse"
                      id="left-menu"
                      :unique-opened="true"
                      text-color="lightgreen"
                      default-active=$router.path
                      router
                      active-text-color="#ffd04b">
              <el-submenu v-for="item in menus" :key="item.id" :index="item.id + ''">
                <template v-slot:title>
                  <i :class=item.icon></i>
                  <span>{{item.menu}}</span>
                </template>
                  <div v-if="item.children">
                    <div v-for="sub_menu in item.children" :key="sub_menu.id">
                      <el-menu-item :index="sub_menu.path">
                        <i :class=sub_menu.icon></i>
                        <span>{{sub_menu.menu}}</span>
                      </el-menu-item>
                    </div>
                  </div>
              </el-submenu>
            </el-menu>
          </el-aside>
        <el-container id="sub-container">
          <el-container id="main-footer">
            <el-header class="el-header">
              <div class="head-logo">
                <span id="span-text">Borden System</span>
                <el-dropdown @command="handleCommand" class="lang-dropdown">
                  <span class="el-dropdown-link">
                    语言：
                     <i class="el-icon-caret-bottom el-icon--right"></i>
                  </span>
                  <template v-slot:dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="cn">中文</el-dropdown-item>
                    <el-dropdown-item command="en">英文</el-dropdown-item>
                   </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-dropdown class="user-dropdown">
                  <span class="el-dropdown-link">
                    您好: {{showUsername}}
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                  </span>
                  <template v-slot:dropdown>
                    <el-dropdown-menu>
                      <!-- icon是修改图标 ，command是点击后传给方法的值-->
                      <el-dropdown-item icon="el-icon-edit">
                        <el-button  @click="toChangePwd">修改密码</el-button>
                      </el-dropdown-item>

                      <el-dropdown-item icon="el-icon-warning">
                        <el-button @click="Logout">退出登录</el-button>
                      </el-dropdown-item>

                      <el-dropdown-item icon="el-icon-user">
                        <el-button  @click="toMyInfo">个人信息</el-button>
                      </el-dropdown-item>

                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              </el-header>
            <el-main id="main-side">
              <router-view></router-view>
            </el-main>
          <el-footer id="footer">
            <a href="#">关于我们</a>
            <span>  |  </span>
            <a href="#">联系我们</a>
            <span>  |  </span>
            <a href="#">招聘信息</a>
            <span>  |  </span>
            <a href="#">友情链接</a>
          </el-footer>
          </el-container>
        </el-container>
      </el-container>
    </div>
</template>

<script>
    import backgroundImage from "../../assets/flash-z.jpg";
    import Cookies from "js-cookie";
    import {getUserMenus, logout} from "../../api/user";

    export default {
        name: "Home",
        data(){
          const menus = [
            {id: 1, menu: "用户管理", icon: "el-icon-s-custom", children: [
                {id: 1-1, menu: "用户列表", icon: "el-icon-user", path: "/user-list"},
                {id: 1-2, menu: "用户添加", icon: "el-icon-edit", path: "/add-user"},
                {id: 1-3, menu: "用户查询", icon: "el-icon-search", path: "/search-user"}]
            },
            {id: 2, menu: "订单管理", icon: "el-icon-s-order", children: [
                {id: 2-1, menu: "订单列表", icon: "el-icon-s-order", path: "/order-list"},
                {id: 2-2, menu: "订单综合查询", icon: "el-icon-search", path: "/search-order"}]
            },
            {id: 3, menu: "套餐管理", icon: "el-icon-s-goods", children: [
                {id: 3-1, menu: "套餐列表", icon: "el-icon-folder", path: "/goods-list"},
                {id: 3-2, menu: "套餐添加", icon: "el-icon-folder-add", path: "/add-goods"},
                {id: 3-3, menu: "套餐综合查询", icon: "el-icon-search", path: "/search-goods"}]
            },
            {id: 4, menu: "投诉管理", icon: "el-icon-question", children: [
                {id: 4-1, menu: "投诉列表", icon: "el-icon-info", path: "/feedback-list"},
                {id: 4-2, menu: "投诉处理", icon: "el-icon-edit-outline", path: "/deal-feedback"},
                {id: 4-3, menu: "投诉综合查询", icon: "el-icon-search", path: "/search-feedback"}]
            },
            {id: 5, menu: "数据统计", icon: "el-icon-s-data", children: [
                {id: 5-1, menu: "用户数据", icon: "el-icon-user", path: "/user-data"},
                {id: 5-2, menu: "订单数据", icon: "el-icon-s-order", path: "/order-data"},
                {id: 5-3, menu: "套餐数据", icon: "el-icon-goods", path: "/goods-data"},
                {id: 5-4, menu: "投诉数据", icon: "el-icon-warning", path: "/feedback-data"},
                {id: 5-5, menu: "数据综合查询", icon: "el-icon-search", path: "/search-data"}]
            },
            {id: 6, menu: "角色管理", icon: "el-icon-s-custom", children: [
                {id: 6-1, menu: "角色列表", icon: "el-icon-s-user", path: "/role-list"},
                {id: 6-2, menu: "角色添加", icon: "el-icon-edit", path: "/add-role"},
                {id: 6-3, menu: "角色综合查询", icon: "el-icon-search", path: "/search-role"}]
            },
            {id: 7, menu: "权限管理", icon: "el-icon-lock", children: [
                {id: 7-1, menu: "权限列表", icon: "el-icon-unlock", path: "/permission-list"},
                {id: 7-2, menu: "权限添加", icon: "el-icon-edit", path: "/add-permission"},
                {id: 7-3, menu: "权限综合查询", icon: "el-icon-search", path: "/search-permission"}]
            },
            {id: 8, menu: "系统管理", icon: "el-icon-s-tools", children: [
                {id: 8-1, menu: "系统设置", icon: "el-icon-setting", path: "/system-setting"},
                {id: 8-2, menu: "发送邮件", icon: "el-icon-message", path: "/send-email"},
                {id: 8-3, menu: "上传文件", icon: "el-icon-upload", path: "/upload-file"}
              ]
            },
          ]
         return {
           backgroundImage,
           // logo,
           menus,
           buttons: [],
           logo: {
             background: `url(${backgroundImage})`,
             backgroundSize: '10% 10%'
           },
           isCollapse: false,
           isLang: true,
           isLogin: false,
         }
  },
    mounted() {
          this.getUserMenus()
          this.toGetUsers()
  },
  methods:{
    toLogin(){
       this.$router.push('login');
     },
    toRegister(){
       this.$router.push('register');
     },
    toChangePwd(){
      this.$router.push('change-pwd').catch(() => {});
    },
    toMyInfo(){
      this.$router.push('my-info').catch(() => {});
    },
    toGetUsers(){
      this.$router.push('user-list').catch(() => {});
    },
    async getUserMenus(){
      await getUserMenus().then(res => {
        console.log('res:', res)
        if (res.code !== 200) {
          return this.$message.error('Get User Menus Failed: ' + res.message)
        }
        if(res.data.menus !== []){
          this.menus = res.data.menus
        }
        if (res.data.buttons !== []){
          this.buttons = res.data.buttons
        }
        sessionStorage.setItem( "button_perms", this.buttons);
      })
    },
    Logout(){
      logout().then(res => {
        if (res.code !== 200) {
          return this.$message.error('Backend Logout Failed: ' + res.message)
        }
        localStorage.clear()
        this.toLogin()
      })
      // return Cookies.get("name")
    },
    toggleCollapse(){
      this.isCollapse = !this.isCollapse;
    },
    toggleLogin(){
      this.isLogin = !this.isLogin;
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      switch (command) {
        case "cn": {
          this.lang = "cn";
          this.$i18n.locale = this.lang;
          break;
        }
        case "en": {
          this.lang = "en";
          this.$i18n.locale = this.lang;
          break;
        }

        default:
          break;
      }
    },
  },
  computed:{
    // eslint-disable-next-line vue/return-in-computed-property
    showUsername(){
      if (localStorage.getItem("access_token")){
        return Cookies.get("name")
      }
      // return "sssssssssssssssssssssssssssss"
      },
  },
}
</script>

<style>
.el-container {
  height: 100vh;
}

.el-header {
  /*333777*/
  background-color: #191970;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*color: #fff;*/
  font-size: 40px;
}

#span-text {
  float: left;
  margin-left: 15px;
  color: #42b983;
}
.head-logo {
  width: 100%;
  height: 100%;
  /*border: 1px solid;*/
  padding: 0 0;
}

/*#logo-img {*/
/*  float: left;*/
/*  width: 15%;*/
/*  height: 100%;*/
/*  vertical-align: middle;*/
/*}*/

.user-dropdown{
  float: right; /* 浮动在右边 */
  font-size: 30px;
  margin-right: 40px; /* 靠右40px */
  }

.lang-dropdown {
  float: right;
}

.el-icon-set-up {
  margin-top: 20px;
  color: #fff;
  cursor: pointer; /* 鼠标放上去是手的形状 */
  font-size: 30px;

}

.el-dropdown-link {
  display: flex;
  margin-top: 20px;
  color: #fff;
  cursor: pointer; /* 鼠标放上去是手的形状 */
  font-size: 20px;
  }

#left-side {
  height: 100%;
  background: #333777;
}

#left-menu {
  background-color: #333777;
  font-weight: bold;
  font-size: 20px;
}
.toggle-button {
  width: 100%;
  height: 60px;
  color: white;
  font-size: 45px;
}

.el-menu-item {
  background-color: #333777;
}

#main-side {
  background-color: #eaedf1;
  background-image: url("../../assets/tech.jpg");
  background-size: cover;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: lightgreen;
}

#footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
}

/*3d旋转魔方*/
.cube {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    transform-style: preserve-3d;
    transform: rotateX(-30deg) rotateY(-80deg);
    animation: rotate linear 20s infinite;
}

/*cube外层*/
.wrap {
    width: 120px;
    height: 200px;
    margin: 20px;
    position: fixed;
    top: 15px;
    right: 15px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    z-index: 22;
}
</style>