<template>
  <div class="email-wrap">
    <h2 class="h-title">邮件发送</h2>
    <div class="mail-btn">
      <el-button type="primary" size="mini" :disabled="isSend" @click="sendMail">发送</el-button>
      <el-button type="warning" size="mini" @click="doCancel">取消</el-button>
    </div>
    <el-form label-width="80px" :model="form" :rules="rules" ref="mailFormRef">
      <el-form-item label="接收邮箱" class="item" prop="receivers"
                    id="mail-receivers" :rules="rules.receivers">
        <el-input
          v-model="form.receivers"
          type="input"
          :rows="2"
          placeholder="请输入邮箱地址,多个邮箱用英文逗号“,”隔开"
        />
      </el-form-item>
      <el-form-item label="邮件标题" class="item" prop="title"
                    id="mail-title" :rules="rules.title">
        <el-input
          v-model="form.title"
          type="input"
          :rows="2"
          placeholder="请输入标题"
        />
      </el-form-item>
      <el-form-item label="邮件正文" class="item" prop="content"
                    id="mail-content" :rules="rules.content">
        <quill-editor
          ref="myQuillEditor"
          v-model="form.content"
          class="ql-editor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @change="onEditorChange($event)"
        ></quill-editor>
      </el-form-item>
      <el-form-item label="选择附件" class="file" id="item">
        <el-upload
          ref="upload"
          drag
          :data="form"
          action="http://localhost:9898/system/upload-file"
          class="upload-demo"
          :on-change="changeFile"
          :on-success="successFile"
          :on-error="errorFile"
          :file-list="fileList"
          :auto-upload="true"
          :before-upload="checkFile"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :headers="headers"
          :limit="10"
          list-type="picture"
          multiple
          :http-request="httpRequest"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

<!--          <template v-slot:trigger>-->
<!--          <el-button size="small" type="success">选取文件</el-button>-->
<!--          </template>-->

          <template v-slot:tip>
          <div class="el-upload__tip">选择文件后，发送，邮件内容和附件会自动发送到指定邮箱，请注意查收</div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { strRebuild, lastSubstring } from '../../utils/tools'
import {sendEmail, uploadFile} from "../../api/system";

export default {
  name: "Email",
  components: {
    quillEditor
  },
  data() {
    const validateReceivers = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入收件人的邮箱'))
       } else {
         callback()
       }
     }
     const validateTitle = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入邮件标题'))
       } else {
         callback()
       }
     }
     const validateContent = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入邮件正文'))
       } else {
         callback()
       }
     }

    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
            ['blockquote', 'code-block'], // 引用，代码块

            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: 'ordered' }, { list: 'bullet' }], // 列表
            [{ script: 'sub' }, { script: 'super' }], // 上下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向

            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题

            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], // 字体
            [{ align: [] }], // 对齐方式

            ['clean'] // 清除字体样式
            // ['image', 'video'], //上传图片、上传视频
          ]
        },
        theme: 'snow'
      },
      rules: {
        receivers: [
            {required: true, message: '请输入接收邮箱', trigger: 'blur' },
            { required: true, trigger: 'blur', validator: validateReceivers }
        ],
        title: [
            {required: true, message: '请输入标题', trigger: 'blur' },
            { required: true, trigger: 'blur', validator: validateTitle }
        ],
        content: [
            {required: true, message: '请输入正文', trigger: 'blur' },
            { required: true, trigger: 'blur', validator: validateContent }
        ]
      },
      form: {
        receivers: '',
        title: '',
        content: '',
        filenames: []
      },
      // 允许的文件类型
      fileType: ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'txt', 'jpg', 'png', 'jpeg', 'html'],
      fileSize: 10,
      fileList: [],
      uploadCode: []
    }
  },
  computed: {
    isSend() {
        return !this.form.receivers || !this.form.title || !this.form.content
    },
    headers() {
      return {
        access_token: localStorage.getItem('access_token')
      }
    }
  },
  methods: {
    onEditorBlur(){//失去焦点事件
    },
    onEditorFocus(){//获得焦点事件
    },
    onEditorChange(){//内容改变事件
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.$refs.upload.uploadFiles = fileList
      },
    handlePreview(file){
      console.log(file.name)
    },
    changeFile(file, filelist) { // 文件状态改变时的钩子 把filelist 赋值给data中的filelist
      this.fileList = filelist
    },
    clear() {
      // 清空附件
      this.$refs.upload.clearFiles()
   },
    checkFile() {
      let flag = true;
      let tip = '';
      const files = this.$refs.upload.uploadFiles;
      files.forEach(item => {
        // 文件过大
        if (item.size > this.fileSize * 1024 * 1024) {
          flag = false
          tip = ' 文件超过' + this.fileSize + 'M'
        }
        // 文件类型不属于可上传的类型
        if (!this.fileType.includes(lastSubstring(item.name, '.'))) {
          flag = false
          tip = ' 文件类型不可上传'
        }
      })
      if (!flag) {
        this.$message.error(tip)
      }
      return flag
    },

    // 字符串重组
    strRebuild(str) {
      return strRebuild(str)
    },

    // onSubmit() {
    //   if (this.form.email === "") {
    //     this.$message.error('请输入收件邮箱')
    //     return
    //   }
    //   if (this.form.title === "") {
    //     this.$message.error('请输入标题')
    //     return
    //   }
    //   if (this.form.content === "") {
    //     this.$message.error('请输入内容')
    //     return
    //   }
    //   setTimeout(() => {
    //     if (this.fileList.length <= 0) {
    //       return false
    //   }
    //     this.$refs.upload.submit() // 手动上传文件
    //   }, 5)
    // },

    async sendMail(){
      await this.$refs['mailFormRef'].validate((valid) => {
        if (valid){
          sendEmail(this.form).then(res => {
            if (res.code !== 200){
              return this.$message.error("Send Email Failed: " + res.message)
            }
            this.$message.success(res.message)
            this.$router.push("home").catch(() => {})
            }).catch(err => {
              console.log.error(err)
          }).catch(ex => {
               console.log(ex)
          })
        } else {
             // this.$refs.userAddFormRef.resetFields();
             return this.$message.error("Please Input Valid Request Data!")
           }
      })
    },

    successFile(file) {
      console.log(file)
    },
    
    errorFile(file) {
      console.log(file)
      this.$message.error("Upload file failed")
    },

    doCancel() {
      this.fileList = []
      this.$router.go(-1)
    },

    httpRequest(param){
      const formData = new FormData()
      const file = param.file
      const filename = file.name
      formData.append("filename", filename)
      formData.append("file", file)
      uploadFile(formData).then(res => {
        if (res.code !== 200){
          return this.$message.error(res.message)
        }
        param.onSuccess()
        this.$refs.upload.clearFiles()
        this.$message.success(res.message)

        this.form.filenames.push(filename)

      }).catch(err => {
        console.log.error(err)
      })
    },

  }
}
</script>

<style scoped>
.email-wrap{
    margin-top:20px;
    margin-right:30px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

}

.mail-btn {
  margin-right: 63%;
}

.el-upload-dragger {
  width: 240px;
  height: 150px;
}

.h-title {
  color: greenyellow;
  font-size: 20px;
}

.el-upload__tip {
  color: red;
}

.item #item .el-form-item__label {
  width: 30%;
  color: lightgreen;
}

.ql-editor {
  height: 250px;
  background-color: honeydew;
}
.file{
    margin-top:5%;
}
</style>