<template>
<wxlogin :appid="appid" :scope="scope" :theme="style" :redirect_uri="redirect_uri" :href="qrcode" rel="external nofollow">
</wxlogin>
</template>

<script>

export default {
  name: "WeChatLogin",
  data(){
    return {
      id: "wechat",
      self_redirect: true,
      appid: "wxaaf65d48662a0526",
      // appid: process.env.VUE_APP_SYSTEM_APPID,
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent("http://localhost:9898/wechat-login"),
      state: Math.ceil(Math.random() * 1000),
      style: 'black',
      qrcode: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZxxx='
    }
  },
  mounted() {
    console.log(this.appid)
  },
  methods: {
}
}
</script>

<style scoped>

</style>