import Vue from 'vue'
import App from './App.vue'
import router from './router'
import qs from 'qs'
import md5 from 'js-md5'
import ElmentUI, {Tooltip} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from "./i18n/i18n";
import locale from 'element-ui/lib/locale/lang/en'
import 'element-theme-chalk';
import wxlogin from 'vue-wxlogin'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.component('wxlogin', wxlogin);

Vue.directive('btnlimit', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: (el, binding) => {
        let isExist = false
        // el 当前绑定的元素 binding.value指令的绑定值
        let btnPerms = sessionStorage.getItem('button_perms');
        // 判断一下是否包含这个元素，如果不包含的话，那就让他爸爸元素把子元素扔进垃圾堆
        if (btnPerms && btnPerms.includes(binding.value)) {
           isExist = true
      }
      //不存在时删除节点
      if (el.parentNode && !isExist) {
           el.parentNode.removeChild(el)
          // el.style.opacity = '0.4'
       }
    }
})

// 设置反向代理，前端请求默认发送到 http://localhost:8443/api
const axios = require('axios');

// axios.defaults.baseURL = 'http://localhost:9898'
axios.defaults.baseURL = process.env.VUE_APP_BASE_DOMAIN
// axios.defaults.baseURL = 'api'
axios.defaults.timeout = 5000 // 请求超时
axios.defaults.headers['Content-Type'] = 'application/json'
// 全局注册，之后可在其他组件中通过 this.$axios 发送数据
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$md5 = md5
Vue.config.productionTip = false
Vue.use(ElmentUI, { locale })
Vue.use(Tooltip)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')