<template>
<div title="个人信息" class="my-info-box">
  <el-form id="my-form" :model="myInfo" ref="myInfo">
    <h1 class="h1">个人信息栏</h1>
      <el-form-item label="用户名:" class="color-item">
        <el-input v-model="myInfo.user_info.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="姓名:" class="color-item">
        <el-input v-model="myInfo.user_info.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="手机号:" class="color-item">
        <el-input v-model="myInfo.user_info.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱:" class="color-item">
        <el-input v-model="myInfo.user_info.email" disabled></el-input>
      </el-form-item>
      <el-form-item label="年龄:" class="color-item">
        <el-input v-model="myInfo.user_info.age"></el-input>
      </el-form-item>
      <el-form-item label="生日:" class="color-item">
        <el-input v-model="myInfo.user_info.birthday"></el-input>
      </el-form-item>
      <el-form-item label="性别:" class="color-item">
        <el-input v-model="myInfo.user_info.gender"></el-input>
      </el-form-item>
      <el-form-item label="地址:" class="color-item">
        <el-input v-model="myInfo.user_info.address"></el-input>
      </el-form-item>
    <h1 class="h1">角色信息栏</h1>
      <el-form-item label="角色名称:" class="color-item">
        <el-input v-model="myInfo.role_info.role_name" disabled></el-input>
      </el-form-item>
      <el-form-item label="角色编码:" class="color-item">
        <el-input v-model="myInfo.role_info.role_code" disabled></el-input>
      </el-form-item>
    <h1 class="h1">工作信息栏</h1>
      <el-form-item label="公司:" class="color-item">
        <el-input v-model="myInfo.job_info.company"></el-input>
      </el-form-item>
      <el-form-item label="公司地址:" class="color-item">
        <el-input v-model="myInfo.job_info.group_address"></el-input>
      </el-form-item>
      <el-form-item label="部门:" class="color-item">
        <el-input v-model="myInfo.job_info.department"></el-input>
      </el-form-item>
      <el-form-item label="职位:" class="color-item">
        <el-input v-model="myInfo.job_info.position"></el-input>
      </el-form-item>
      <el-form-item label="职位级别:" class="color-item">
        <el-select v-model="myInfo.job_info.position_level" style="width: 100%"
                   placeholder="请选择职级" clearable disabled>
          <el-option v-for="(item, index) in levels"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工编号:" class="color-item">
        <el-input v-model="myInfo.job_info.job_num" disabled></el-input>
      </el-form-item>
      <el-form-item label="员工状态:" class="color-item">
        <el-select v-model="myInfo.job_info.job_status" style="width: 100%"
                   placeholder="请选择工作状态" clearable disabled>
          <el-option v-for="(item, index) in jobStatus"
                     :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    <el-form-item class="btns">
          <el-button class="btn-confirm" size="normal" type="primary" @click="handleUpdate">保存</el-button>
          <el-button class="btn-cancel" size="normal" type="warning" @click="handleClose">取消</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import {getMyInfo, updateMyInfo} from "../../api/user";

export default {
  name: "MyInfo",
  data () {
    const levels = [
      {id: 1, name: "初级(primary)"},
      {id: 2, name: "中级(middle)"},
      {id: 3, name: "高级(senior)"},
      {id: 4, name: "资深(professional)"},
    ]
    const jobStatus = [
        {id: 0, name: "离职"},
        {id: 1, name: "在职"},
    ]
    return {
      levels,
      jobStatus,
      myInfo: {
        user_info: {
          username: "",
          name: "",
          phone: "",
          email: "",
          age: "",
          gender: "",
          birthday: "",
          address: "",
        }, 
        role_info: {
          role_name: "",
          role_code: ""
        },
        job_info: {
          company: "",
          group_address: "",
          department: "",
          position: "",
          position_level: "",
          job_num: "",
          job_status: "",
        }
      },
    }
  },

  mounted() {
    this.queryMyInfo()
  },

  methods: {
    async queryMyInfo(){
      await getMyInfo().then(res => {
        console.log(res)
        if (res.code !== 200){
          return this.$message.error(res.message)
        }
        this.myInfo = res.data
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    handleClose(){
      this.$router.go(-1)
    },
    async handleUpdate(){
      const form = {
        user_info: this.myInfo.user_info,
        role_info: this.myInfo.role_info,
        job_info: this.myInfo.job_info,
      }
      this.$confirm(`确定更新?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
      await updateMyInfo(form).then(res => {
        if (res.code !== 200){
          return this.$message.error("Modify my info failed: " + res.message)
        }
        this.$message.success('Message: ' + res.message)
        this.$forceUpdate()
      }).catch(err => {
        return this.$message.error("Exception: " + err)
      })
      }).catch(err => {
        return this.$message.info(err)
      })
    },
  },
}
</script>

<style scoped>

#my-form {
  width: 50%;
  margin: 0 auto;
}

.color-item .el-form-item__label {
  margin-left: -20%;
  width: auto;
  color: lightgreen;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn-cancel {
  margin-left: 100px;
  width: 70px;
  height: 40px;
}

.h1 {
  color: greenyellow;
}

</style>