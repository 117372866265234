<template>
  <div class="register-container" :style="bg">
    <!-- 页面的主体css样式 -->
    <div class="register_box">
      <el-form class="register_form" :model="registerForm" :rules="registerRules" ref="registerFormRef">
        <p class="register-head">新用户注册</p>
        <!-- 用户名 -->
        <el-form-item label="用户名" class="color-item" prop="username" id="username" :rules="registerRules.username">
          <el-input type="text" v-model="registerForm.username" placeholder="用户名"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 姓名 -->
        <el-form-item label="姓名" class="color-item" prop="name" id="name" :rules="registerRules.name">
          <el-input type="text" v-model="registerForm.name" placeholder="姓名"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item label="手机号" class="color-item" prop="phone" id="phone" :rules="registerRules.phone">
          <el-input type="number" placeholder="手机号" :maxlength="11"
                    v-model="registerForm.phone" prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
        <!-- 密码框 -->
        <el-form-item label="密码" class="color-item" prop="password" id="password" :rules="registerRules.password">
          <el-input type="password" v-model="registerForm.password" placeholder="密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 确认密码框 -->
        <el-form-item label="确认密码" class="color-item" prop="confirm_pwd" id="confirm_pwd" :rules="registerRules.confirm_pwd">
          <el-input type="password" v-model="registerForm.confirm_pwd" placeholder="确认密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" class="color-item" prop="email" id="email">
          <el-input type="text" v-model="registerForm.email" placeholder="邮箱"
                    prefix-icon="el-icon-message"></el-input>
        </el-form-item>
        <!-- 手机验证码 -->
<!--        <el-form-item label="*验证码" class="color-item" prop="phone_code" id="phone_code">-->
<!--        <el-input placeholder="手机验证码" v-model="registerForm.phone_code">-->
<!--          <div id="phoneCode">-->
<!--            <van-button-->
<!--              type="danger"-->
<!--              plain-->
<!--              size="small"-->
<!--              :disabled="btnIsDisabled"-->
<!--              @click="sendCode"-->
<!--            >{{msg}}</van-button>-->
<!--          </div>-->
<!--        </el-input>-->
<!--        </el-form-item>-->
                <!-- 图形验证码 -->
        <el-form-item label="验证码" class="color-item" prop="verify_code" id="verify_code" :rules="registerRules.verify_code">
          <el-input
                type="text"
                ref="code"
                prefix-icon="el-icon-document-checked"
                clearable
                placeholder="验证码"
                v-model="registerForm.verify_code"
                keyup.enter.native="handleRegister"
            ></el-input>
          <div class="divVerifyCode">
          <img :src="codeImg" @click="loadCode" id="imgVerifyCode" title="点击刷新" alt="点击刷新" />
          </div>
        </el-form-item>
        <el-form-item class="btns">
        <el-button type="primary" block class="btn-register" :disabled="isRegister"
                   @click="handleRegister">立即注册</el-button>
        </el-form-item>
        <a href="#">
        <span class="to-login" @click="toLogin">已有账号？ 立即登录</span>
        </a>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getCode, register} from '../../api/user'
import backgroundImage from '../../assets/Register.jpg'
export default {
  name: "Register",
  data() {
     const validateUsername = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入用户名'))
       } else {
         if (value.length > 20 || value.length < 3) {
           callback(new Error('请输入小于3-20位用户名'))
         } else {
           callback()
         }
       }
     }
     const validateName = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输姓名'))
       } else if (value.length > 20) {
         callback(new Error('请输入长度小于20位的姓名'))
       } else {
         callback()
       }
     }
     const validatePassword = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入密码'))
       } else if (value.length < 8 ||value.length > 20) {
         callback(new Error('请输入8-20位密码'))
       } else {
         callback()
       }
     }
     const validateConfirmPwd = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入确认密码'))
       } else if (value.length < 8 ||value.length > 20) {
         callback(new Error('请输入8-20位密码'))
       } else if (value !== this.registerForm.password) {
         callback(new Error('两次输入密码不一致'))
       } else {
         callback()
       }
     }
    const validatePhone = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输手机号'))
       } else if (value.length !== 11) {
         callback(new Error('请输手11位数机号'))
      } else {
         callback()
       }
     }
     const validateVerifyCode = (rule, value, callback) => {
       if (value === "" || value.length !== 6) {
         callback(new Error('请输入6位认证码'))
       } else {
         callback()
       }
     }
     const validateEmail = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输入正确格式的邮箱'))
       } else {
         callback()
       }
     }
     return {
       backgroundImage,
       codeImg: '',
       genders: [{name: '男'}, {name: '女'}, {name: '未知'}],
       bg:{
         background:`url(${backgroundImage})`,
         backgroundSize:'100% 100%'
       },
       registerForm: {
         username: '',
         name: '',
         phone: '',
         password: '',
         confirm_pwd: '',
         verify_code:'',
         email: '',
       },
       registerRules: {
         username: [
             { required: true, message: '请输入用户名', trigger: 'blur' },
             { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateUsername }],
         name: [
             { required: true, message: '请输入姓名', trigger: 'blur' },
             { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateName }],
         phone: [
             { required: true, message: '请输入手机号', trigger: 'blur' },
             { min: 11, max: 11, message: '长度为11个数字', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validatePhone }],
         password: [
             { required: true, message: '请输入密码', trigger: 'blur' },
             { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validatePassword }],
         confirm_pwd: [
             { required: true, message: '请输入确认密码', trigger: 'blur' },
             { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateConfirmPwd }],
         verify_code:  [
             { required: true, message: '请输入验证码', trigger: 'blur' },
             { min: 6, max: 6, message: '长度为6个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateVerifyCode }],
         email:  [
             { required: true, message: '请输入邮箱', trigger: 'blur' },
             { min: 6, max: 32, message: '长度为8-32个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateEmail }],
       },
       capsTooltip: false,
       loading: false,
       showDialog: false,
       showEditBtn: false,
       redirect: undefined,
       otherQuery: {}
     }
   },

    mounted() {
     this.loadCode();
   },

    computed: {
    isRegister() {
        return !this.registerForm.username || !this.registerForm.password || !this.registerForm.name ||
            !this.registerForm.phone || !this.registerForm.confirm_pwd || this.registerForm.email ||
            !this.registerForm.verify_code
    }
  },

    methods: {
     async handleRegister() {

         let form = {
           username: this.registerForm.username,
           password: this.$md5(this.registerForm.password),
           name: this.registerForm.name,
           phone: this.registerForm.phone,
           verify_code: this.registerForm.verify_code,
           email: this.registerForm.email,
         };
         console.log(form)
         // 注册
         await this.$refs['registerFormRef'].validate((valid) => {
           if (valid) {
           register(form).then(res => {
           console.log('res', res)
             if (res.code !== 200) {
               return this.$message.error('Register Failed: ' + res.message)
             }
             this.$message.success('Message: ' + res.message)

             // 注册成功跳转至登录页面
             this.$router.push('login')

             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + ex.message)
             }
             })
             }
         })
     },
     // 跳转至登录页面
     toLogin(){
       this.$router.push('login');
     },
     // 认证码
     async loadCode() {
       let _this = this;
       await getCode().then(res => {
        console.log(res)
        return 'data:image/png;base64,' + btoa(
              new Uint8Array(res)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
      }).then(data => {
        console.log(data)
        _this.codeImg = data;
       }).catch(ex => {
         console.error(ex);
       })
     },
}
}
</script>

<style>
.register-container {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('../../assets/Register.jpg');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%
}

.register_box {
  width: 40%;
  height: 820px;
  position: absolute;
  top: -35%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  border-radius: 20px;
  margin-top: 10px;
}

.register_form {
  text-align:center;
  margin-top: 15%;
}

.divVerifyCode {
  position: absolute;
  top: 0;
  right: -85px;
  z-index: 5;
  height: 40px;
  margin: 0;
}

#imgVerifyCode {
  float: left;
  height: 40px;
  width: 85px;
  border-radius: 5px;
  cursor: pointer
}

.color-item .el-form-item__label {
  text-align: left;
  margin-left: -30%;
  width: 30%;
  color: lightgreen;
}

#username, #name, #password, #confirm_pwd, #phone, #email, #phone_code {
  width: 50%;
  margin: 25px auto;
}

#verify_code {
  width: 50%;
  height: 40px;
  padding: 0;
  margin: 25px auto;
}

.btn-register {
  position: initial;
}

.to-login {
  /*color: aliceblue;*/
}

.register-head {
  color: lightgreen
}

</style>