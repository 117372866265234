import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  home: 'home',
  routeNmae: {
    home: 'home',
    article: 'article',
    'menu2-2': 'menu2-2',
    'menu2-3': 'menu2-3'
  },
  rightMenu: {
    close: 'close',
    closeOther: 'closeOther',
    closeAll: 'closeAll'
  },
  ...enLocale   //  合并element-ui内置翻译
}

export default en
