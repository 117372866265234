<template>
<div title="添加角色" class="add-role-container">
  <div class="add_role_box">
      <el-form class="role_add_form" :model="roleAddForm" :rules="roleAddRules" ref="roleAddFormRef">
        <p class="role-add-head">添加角色</p>
        <!-- 角色名 -->
        <el-form-item label="角色名" class="color-item" prop="role_name"
                      id="role_name" :rules="roleAddRules.role_name">
          <el-input type="text" v-model="roleAddForm.role_name" placeholder="角色名"
                    prefix-icon="el-icon-s-check"></el-input>
        </el-form-item>
        <!-- 角色编码 -->
        <el-form-item label="角色编码" class="color-item" prop="code"
                      id="role_code" :rules="roleAddRules.code">
          <el-input type="text" v-model="roleAddForm.code" placeholder="角色编码"
                    prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item label="角色状态" class="color-item" prop="role_status" id="role_status">
        <el-select id="role-status-select" v-model="roleAddForm.status"
                   filterable
                   style="width: 100%"
                   placeholder="请选择角色状态"
                   clearable>
          <el-option
              v-for="(item, index) in roleStatus"
              :key="index"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" :disabled="isAdd" @click="handleAddRole">添加</el-button>
          <el-button @click="closeAddRole">取消</el-button>
        </el-form-item>
      </el-form>
  </div>
</div>
</template>

<script>
import {addRole} from "../../api/user";

export default {
  name: "AddRole",
  data() {
    const roleStatus = [
      {id: 0, name: "禁用"},
      {id: 1, name: "启用"},
    ]
    const validateRoleName = (rule, value, callback) => {
       if (value ==='') {
         callback(new Error('请输入角色名'))
       } else {
         if (value.length > 32 || value.length < 3) {
           callback(new Error('请输入小于3-32位角色名'))
         } else {
           callback()
         }
       }
     }
    const validateCode = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输角色编码'))
       } if (value.length > 32 || value.length < 4) {
           callback(new Error('请输入小于4-32位角色编码'))
       } else {
         callback()
       }
     }
    return {
      roleStatus,
      roleAddForm: {
        role_name: "",
        code: "",
        status: 1,
      },
      roleAddRules: {
         role_name: [
             { required: true, message: '请输入角色名', trigger: 'blur' },
             { min: 3, max: 20, message: '长度在 3 到 32 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateRoleName }],
         code: [
             { required: true, message: '请输入角色编码', trigger: 'blur' },
             { min: 1, max: 20, message: '长度在 4 到 32 个字符', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: validateCode }],
        status: []
       },
    }
  },

  computed: {
    isAdd() {
        return !this.roleAddForm.role_name || !this.roleAddForm.code || !this.roleAddForm.status
    }
  },

  methods: {
    async handleAddRole() {
      const form = {
        role_name: this.roleAddForm.role_name,
        code: this.roleAddForm.code,
        status: this.roleAddForm.status
      }

      await this.$refs['roleAddFormRef'].validate((valid) => {
           if (valid) {
             addRole(form).then(res => {
             console.log('res:', res)
             if (res.code !== 200) {
               return this.$message.error('Add Role Failed: ' + res.message)
             }
             this.$message.success('Message: ' + res.message);
             this.$router.push('role-list').catch(() => {});
             }).catch(ex => {
               console.log(ex)
               if (ex.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + ex.message)
             }
             })
           } else {
             // this.$refs.userAddFormRef.resetFields();
             return this.$message.error("Please Input Valid Request Data!")
           }
         })
    },
    closeAddRole() {
      this.$refs.roleAddFormRef.resetFields();
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.add_role_box {
  width: 40%;
  height: 500px;
  position: absolute;
  top: -5%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  border-radius: 20px;
  margin-top: 20px;
}

.role_add_form {
  text-align: center;
  margin-top: 10%;
}

.role-add-head {
  color: greenyellow;
  font-size: 20px;
}

#role_name, #role_code, #role_status {
  width: 50%;
  margin: 25px auto;
}
</style>