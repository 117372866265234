<template>
  <div class="change_pwd_container">
    <div class="change_pwd_box">
      <!-- 表单区域 -->
      <el-form class="change_pwd_form" :model="changePwdForm" :rules="changePwdRules" ref="changePwdFormRef">
        <p class="change-pwd-head">修改密码</p>
        <!-- 旧密码 -->
        <el-form-item label="旧密码" class="color-item" prop="old-pwd" id="old-pwd" :rules="changePwdRules.old_pwd">
          <el-input type="password" v-model="changePwdForm.old_pwd" placeholder="旧密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 新密码 -->
        <el-form-item label="新密码" class="color-item" prop="new-pwd" id="new-pwd" :rules="changePwdRules.new_pwd">
          <el-input type="password" v-model="changePwdForm.new_pwd" placeholder="新密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item label="确认密码" class="color-item" prop="confirm-pwd" id="confirm_pwd" :rules="changePwdRules.confirm_pwd">
          <el-input type="password" v-model="changePwdForm.confirm_pwd" placeholder="确认密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>

<!--        </div>-->
        <!--按钮区域-->
        <el-form-item class="btns">
          <el-button type="primary" class="btn-change-pwd" size="medium"
                     :disabled="isChange" @click="handleChangePwd">提交</el-button>
          <el-button type="warning" class="cancel-btn" size="medium" @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {changePwd} from "../../api/user";

export default {
  name: "ChangePwd",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('请输入密码'))
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else {
        callback()
      }
    }
    const validateConfirmPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('请输入确认密码'))
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else if (value !== this.changePwdForm.new_pwd) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      changePwdForm: {
        old_pwd: '',
        new_pwd: '',
        confirm_pwd: ''
      },
      changePwdRules: {
        old_pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validatePassword}],
        new_pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validatePassword}],
        confirm_pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validateConfirmPwd}]
      },
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },

  computed: {
    isChange() {
        return !this.changePwdForm.old_pwd || !this.changePwdForm.new_pwd || !this.changePwdForm.confirm_pwd
    }
  },

  methods: {
    async handleChangePwd() {

      if (window.localStorage.get('name') === "") {
        console.error("请先登录")
      } else {
        console.log('修改密码')
      }

      let form = {
        old_pwd: this.$md5(this.changePwdForm.old_pwd),
        new_pwd: this.$md5(this.changePwdForm.new_pwd),
      };
      await this.$refs['changePwdFormRef'].validate((valid) => {
        if (valid) {
          changePwd(form).then(res => {
            console.log('res', res)
            if (res.code !== 200) {
              return this.$message.error('Change Password Failed: ' + res.message)
            }
            this.$message.success('Message: ' + res.message)
            console.log(res.data.access_token)

            this.$router.push('home')

          }).catch(ex => {
            console.log(ex)
            if (ex.response.status === 400) {
              return this.$message.error("Please Input Valid Request Data: " + ex.message)
            }
          })
        } else {
             this.$refs.changePwdFormRef.resetFields();
           }
      })
    },
    handleCancel() {
      this.$router.go(-1);
    },

  }
}
</script>

<style>
.change_pwd_container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('../../assets/Register.jpg');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.change_pwd_box {
  width: 40%;
  height: 600px;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
}

.color-item .el-form-item__label {
  margin-left: -40%;
  width: 40%;
  color: lightgreen;
}

.change_pwd_form {
  margin-top: 20%;
}

#old-pwd, #new-pwd, #confirm_pwd {
  width: 50%;
  margin: 25px auto;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn-change-pwd, .cancel-btn {
  width: 45%;
  position: inherit;
}

.change-pwd-head {
  color: #FFD700
}

</style>