import Vue from 'vue'
import Router from 'vue-router'
// 导入刚才编写的组件
import Home from '../components/home/Home.vue'
import Login from '../components/user/Login.vue'
import Register from '../components/user/Register.vue'
import ChangePwd from "../components/user/ChangePwd";
import ResetPwd from "../components/user/ResetPwd";
import Users from "../components/user/Users";
import Roles from "../components/role/Roles";
import AddUser from "../components/user/AddUser";
import MyInfo from "../components/user/MyInfo";
import UserInfo from "../components/user/UserInfo";
import NotFound from "../components/NotFound";
import InternalError from "../components/InternalError";
import RoleInfo from "../components/role/RoleInfo";
import AddRole from "../components/role/AddRole";
import SMSCodeLogin from "../components/user/SMSCodeLogin";
import Permissions from "../components/permission/Permissions"
import AddPermission from "../components/permission/AddPermission";
import PermissionInfo from "../components/permission/PermissionInfo";
import RoleAuthorize from "../components/role/RoleAuthorize";
import WeChatLogin from "../components/user/WeChatLogin";
import Email from "../components/system/Email";
import UploadFile from "../components/system/UploadFile";

Vue.use(Router)

const router =  new Router({
    routes: [
        {
            path: '/',
            redirect: '/login',
            meta: {
                title: '用户登录',
            }
        },
        // 下面都是固定的写法
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                title: '我的技术测试-首页',
                requireAuth: true,
            },
            children: [
                {
                    path: '/my-info',
                    name: 'MyInfo',
                    method: 'GET',
                    component: MyInfo,
                    meta: {
                        title: '个人信息',
                        requireAuth: true,
                    }
                },
                {
                    path: '/user-info',
                    name: 'UserInfo',
                    method: 'GET',
                    component: UserInfo,
                    meta: {
                        title: '用户信息',
                        requireAuth: true,
                    }
                },
                {
                    path: '/user-list',
                    name: 'Users',
                    method: "GET",
                    component: Users,
                    meta: {
                        title: "用户列表",
                        requireAuth: true
                    }
                },
                {
                    path: '/add-user',
                    name: 'AddUser',
                    method: 'POST',
                    component: AddUser,
                    meta: {
                        title: '添加用户',
                        requireAuth: true,
                    }
                },
                {
                    path: '/role-list',
                    name: 'Roles',
                    method: 'GET',
                    component: Roles,
                    meta: {
                        title: '角色列表',
                        requireAuth: true,
                    }
                },
                {
                    path: '/role-info',
                    name: 'RoleInfo',
                    method: 'GET',
                    component: RoleInfo,
                    meta: {
                        title: '角色信息',
                        requireAuth: true,
                    }
                },
                {
                    path: '/add-role',
                    name: 'AddRole',
                    method: 'POST',
                    component: AddRole,
                    meta: {
                        title: '添加角色',
                        requireAuth: true,
                    }
                },
                {
                    path: '/role-perms',
                    name: 'RoleAuth',
                    method: 'POST',
                    component: RoleAuthorize,
                    meta: {
                        title: '角色授权',
                        requireAuth: true,
                    }
                },
                {
                    path: '/permission-list',
                    name: 'Permissions',
                    method: 'GET',
                    component: Permissions,
                    meta: {
                        title: '权限列表',
                        requireAuth: true,
                    }
                },
                {
                    path: '/permission-info',
                    name: 'PermissionInfo',
                    method: 'GET',
                    component: PermissionInfo,
                    meta: {
                        title: '权限信息',
                        requireAuth: true,
                    }
                },
                {
                    path: '/add-permission',
                    name: 'AddPermission',
                    method: 'POST',
                    component: AddPermission,
                    meta: {
                        title: '添加权限',
                        requireAuth: true,
                    }
                },
                {
                    path: '/send-email',
                    name: 'SendEmail',
                    method: 'POST',
                    component: Email,
                    meta: {
                        title: '发送邮件',
                        requireAuth: true,
                    }
                },
                {
                    path: '/upload-file',
                    name: 'UploadFile',
                    method: 'POST',
                    component: UploadFile,
                    meta: {
                        title: '上传文件',
                        requireAuth: true,
                    }
                },
            ]
        },
        {
            path: '/login',
            name: 'Login',
            method: 'POST',
            component: Login,
            meta: {
                title: '我的技术测试-登录',
            }
        },
        {
            path: '/phone-login',
            name: 'SMSCodeLogin',
            method: 'POST',
            component: SMSCodeLogin,
            meta: {
                title: '手机验证码登录',
            }
        },
        {
            path: '/register',
            name: 'Register',
            method: 'POST',
            component: Register,
            meta: {
                title: '用户注册',
            }
        },
        {
            path: '/change-pwd',
            name: 'ChangePwd',
            method: 'PATCH',
            component: ChangePwd,
            meta: {
                title: '修改密码',
                requireAuth: true,
            }
        },
        {
            path: '/reset-pwd',
            name: 'ResetPwd',
            method: 'POST',
            component: ResetPwd,
            meta: {
                title: '重置密码',
            }
        },
        {
            path: '/wechat-login',
            name: 'WechatLogin',
            method: 'PATCH',
            component: WeChatLogin,
            meta: {
                title: '微信扫码登录',
            }
        },
        {
            path: '/404',
            name: 'NotFound',
            method: 'GET',
            component: NotFound,
            meta: {
                title: '404页面',
            }
        },
        {
            path: '/500',
            name: 'InternalError',
            method: 'GET',
            component: InternalError,
            meta: {
                title: '500页面',
            }
        },
        {
            path: "*", // 此处需特别注意置于最底部
            redirect: "/404"
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.requireAuth) {
        if (localStorage.getItem('access_token')) {
            next();
        } else {
            console.warn('该页面需要登录才能访问')
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next();
    }
})

export default router;
