<template>
  <div class="email-wrap">
    <h2 class="h-title">文件上传</h2>
    <el-form label-width="80px" :model="form">
      <el-form-item label="产品编号" class="item" prop="receivers"
                    id="mail-receivers">
        <el-input
          v-model="form.file_id"
          type="input"
          :rows="2"
          placeholder="请输入产品编号"
        />
      </el-form-item>
      <el-form-item label="文件类型" class="item" prop="title"
                    id="mail-title">
        <el-input
          v-model="form.file_content"
          type="input"
          :rows="2"
          placeholder="请输入文件类型"
        />
      </el-form-item>
      <el-form-item label="选择附件" class="file" id="item">
        <el-upload
          ref="upload"
          drag
          :data="form"
          action="http://localhost:9898/system/upload-file"
          class="upload-demo"
          :on-change="changeFile"
          :on-success="successFile"
          :on-error="errorFile"
          :file-list="fileList"
          :auto-upload="false"
          :before-upload="checkFile"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :headers="headers"
          :limit="10"
          list-type="picture"
          multiple
          :http-request="httpRequest"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="mail-btn">
<!--      <el-button type="primary" size="mini" :disabled="isSend" @click="onSubmit">上传</el-button>-->
      <el-button type="primary" size="mini" @click="onSubmit">上传</el-button>
      <el-button type="warning" size="mini" @click="doCancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import {lastSubstring, strRebuild} from "../../utils/tools";
import {uploadFile} from "../../api/system";

export default {
  name: "UploadFile",
  data() {
    return {
      form: {
        file_id: '',
        file_content: '',
        filenames: []
      },
      // 允许的文件类型
      fileType: ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'txt', 'jpg', 'png', 'jpeg', 'html'],
      fileSize: 10,
      fileList: [],
      uploadCode: []
    }
  },
  computed: {
    isSend() {
        return !this.form.receivers || !this.form.title || !this.form.content
    },
    headers() {
      return {
        access_token: localStorage.getItem('access_token')
      }
    }
  },
  methods: {
    onEditorBlur(){//失去焦点事件
    },
    onEditorFocus(){//获得焦点事件
    },
    onEditorChange(){//内容改变事件
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.$refs.upload.uploadFiles = fileList
      },
    handlePreview(file){
      console.log(file.name)
    },
    changeFile(file, filelist) { // 文件状态改变时的钩子 把filelist 赋值给data中的filelist
      this.fileList = filelist
    },
    clear() {
      // 清空附件
      this.$refs.upload.clearFiles()
   },
    checkFile() {
      let flag = true;
      let tip = '';
      const files = this.$refs.upload.uploadFiles;
      files.forEach(item => {
        // 文件过大
        if (item.size > this.fileSize * 1024 * 1024) {
          flag = false
          tip = ' 文件超过' + this.fileSize + 'M'
        }
        // 文件类型不属于可上传的类型
        if (!this.fileType.includes(lastSubstring(item.name, '.'))) {
          flag = false
          tip = ' 文件类型不可上传'
        }
      })
      if (!flag) {
        this.$message.error(tip)
      }
      return flag
    },

    // 字符串重组
    strRebuild(str) {
      return strRebuild(str)
    },

    onSubmit() {
      setTimeout(() => {
        if (this.fileList.length <= 0) {
          return false
      }
        this.$refs.upload.submit() // 手动上传文件
      }, 5)
    },

    successFile(file) {
      console.log(file)
    },

    errorFile(file) {
      console.log(file)
      this.$message.error("Upload file failed")
    },

    doCancel() {
      this.fileList = []
      this.$router.go(-1)
    },

    httpRequest(param){
      const formData = new FormData()
      const file = param.file
      const filename = file.name
      formData.append("filename", filename)
      formData.append("file", file)
      formData.append("file_id", this.form.file_id)
      formData.append("file_content", this.form.file_content)
      uploadFile(formData).then(res => {
        if (res.code !== 200){
          return this.$message.error(res.message)
        }
        param.onSuccess()
        this.$refs.upload.clearFiles()
        this.$message.success(res.message)

        this.form.filenames.push(filename)

      }).catch(err => {
        console.log.error(err)
      })
    },

  }
}
</script>

<style scoped>
.email-wrap{
    margin-top:20px;
    margin-right:30px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

}

/*.mail-btn {*/
/*  margin-right: 40%;*/
/*}*/

.el-upload-dragger {
  width: 240px;
  height: 150px;
}

.h-title {
  color: greenyellow;
  font-size: 20px;
}

.el-upload__tip {
  color: red;
}

.item #item .el-form-item__label {
  width: 30%;
  color: lightgreen;
}

.ql-editor {
  height: 250px;
  background-color: honeydew;
}
.file{
    margin-top:5%;
}
</style>