<template>
  <div class="reset_pwd_container">
  <div class="reset-pwd-box">
          <!-- 表单区域 -->
      <el-form class="reset_pwd_form" :model="resetPwdForm" :rules="resetPwdRules" ref="resetPwdFormRef">
        <p class="reset-pwd-head">重置密码</p>
        <!-- 手机号 -->
        <el-form-item label="手机号" class="color-item" prop="phone" id="phone" :rules="resetPwdRules.phone">
          <el-input v-model="resetPwdForm.phone" placeholder="手机号"
                    prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
        <!-- 手机验证码 -->
        <el-form-item label="验证码" class="color-item" prop="phone_code" id="phone-code" :rules="resetPwdRules.phone_code">
            <el-input
                  type="text"
                  ref="code"
                  prefix-icon="el-icon-document-checked"
                  clearable
                  placeholder="手机验证码"
                  v-model="resetPwdForm.phone_code"
                  keyup.enter.native="handleResetPwd"
              ></el-input>
          <div class="divSmsCode">
            <el-button
                @click="sendSMSCode"
                :class="{'disabled-style':getCodeBtnDisable}"
                :disabled="getCodeBtnDisable">{{codeBtnWord}}</el-button>
          </div>
        </el-form-item>
        <!-- 新密码 -->
        <el-form-item label="新密码" class="color-item" prop="new_pwd" id="new-pwd" :rules="resetPwdRules.new_pwd">
          <el-input type="password" v-model="resetPwdForm.new_pwd" placeholder="新密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item label="确认密码" class="color-item" prop="confirm_pwd" id="confirm-pwd" :rules="resetPwdRules.confirm_pwd">
          <el-input type="password" v-model="resetPwdForm.confirm_pwd" placeholder="确认密码"
                    prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>

        <!--按钮区域-->
        <el-form-item class="btns">
          <el-button type="primary" class="btn-reset-pwd" @click="handleResetPwd" >提交</el-button>
          <el-button type="warning" class="btn-cancel" @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
  </div>
  </div>
</template>

<script>
import {resetPwd, sendSmsCode} from "../../api/user";

export default {
  name: "ResetPwd",
  data() {
    const validatePhone = (rule, value, callback) => {
       if (value === "") {
         callback(new Error('请输手机号'))
       } else if (value.length !== 11) {
         callback(new Error('请输手11位手机号'))
      } else {
         callback()
       }
     }
    const validatePhoneCode = (rule, value, callback) => {
       if (value === "" || value.length !== 6) {
         callback(new Error('请输入6位手机验证码'))
       } else {
         callback()
       }
     }
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('请输入密码'))
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else {
        callback()
      }
    }
    const validateConfirmPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('请输入确认密码'))
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else if (value !== this.resetPwdForm.new_pwd) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      resetPwdForm: {
        phone: '',
        phone_code: '',
        new_pwd: '',
        confirm_pwd: '',
      },
      resetPwdRules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '长度为11个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validatePhone}],
        phone_code: [
          {required: true, message: '请输入手机验证码', trigger: 'blur'},
          {min: 6, max: 6, message: '长度为6个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validatePhoneCode}],
        new_pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validatePassword}],
        confirm_pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'},
          {required: true, trigger: 'blur', validator: validateConfirmPwd}]
      },
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      waitTime:61, // 获取验证码按钮失效时间
      dialogVisible: false,
      capsTooltip: false,
      loading: false,
      showDialog: false,
      showEditBtn: false,
      redirect: undefined,
      otherQuery: {}
    }
  },

  computed: {
    getCodeBtnDisable: {
      get () {
        if (this.waitTime === 61) {
          return !this.resetPwdForm.phone;
        }
        return true
      },
      set () {}
    },

    isReset() {
        return !this.resetPwdForm.phone || !this.resetPwdForm.phone_code ||
            !this.resetPwdForm.new_pwd || !this.resetPwdForm.confirm_pwd
    }
  },

  methods: {
    async handleResetPwd(){
      console.log(this.resetPwdForm)
      let form = {
           phone: this.resetPwdForm.phone,
           new_pwd: this.$md5(this.resetPwdForm.new_pwd),
           phone_code: this.resetPwdForm.phone_code
         };
      await this.$refs['resetPwdFormRef'].validate((valid) => {
           if (valid) {
             resetPwd(form).then(res => {
                if (res.code !== 200){
                  return this.$message.error(res.message)
                }
                this.$message.success(res.message)
                this.$router.push("login")
              }).catch(err => {
                console.log(err)
               if (err.status === 400) {
               return this.$message.error("Please Input Valid Request Data: " + err.message)
               }
              })
           } else {
             this.$refs.resetPwdFormRef.resetFields();
           }
      })
    },
    handleCancel() {
      this.$router.go(-1);
    },
    async sendSMSCode() {
         await sendSmsCode({phone: this.resetPwdForm.phone}).then(res => {
           console.log(res)
           if (res.code !== 200) {
             console.error(res.message)
             return this.$message.error('Send SMS Code Failed: ' + res.message)
               }
           this.$message.success('Message: ' + res.message)
         }).catch(ex => {
           console.error(ex);
         })
         // 因为下面用到了定时器，需要保存this指向
        let that = this
        that.waitTime--
        that.getCodeBtnDisable = true
        this.codeBtnWord = `${this.waitTime}s 后重新获取`
        let timer = setInterval(function(){
            if(that.waitTime>1){
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
            }else{
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
            }
        },1000)
       },
    handleError() {
         console.log(this)
       },
  }
}
</script>

<style>
.reset_pwd_container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('../../assets/Register.jpg');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.reset-pwd-box {
  width: 40%;
  height: 600px;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #00000090;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
}

.color-item .el-form-item__label {
  margin-left: -30%;
  width: 30%;
  color: lightgreen;
}

.reset_pwd_form {
  margin-top: 20%;
}

#phone, #new-pwd, #confirm-pwd {
  width: 50%;
  margin: 25px auto;
}

#phone-code {
  width: 50%;
  height: 40px;
  padding: 0;
  margin: 25px auto;
}

.divSmsCode {
  position: absolute;
  top: 0;
  right: -60px;
  z-index: 5;
  width: 60px;
  height: 40px;
  margin: 0;
}

.btn-reset-pwd, .btn-cancel {
  width: 45%;
  position: inherit;
}

.reset-pwd-head {
  color: lightgreen;
}
</style>