import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const cn = {
  home: '主页',
  routeNmae: {
    home: '主页',
    article: '文章管理',
    'menu2-2': '二级-2',
    'menu2-3': '二级-3',
  },
  rightMenu: {
    close: '关闭',
    closeOther: '关闭其他',
    closeAll: '全部关闭'
  },

  ...zhLocale   //  合并element-ui内置翻译
}

export default cn
