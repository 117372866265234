<template>
<div class="table-container">
  <el-breadcrumb id="breadcrumb" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    <el-breadcrumb-item>权限列表</el-breadcrumb-item>
  </el-breadcrumb>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入查询内容" v-model="queryInfo.keyword">
          <template v-slot:append>
          <el-button icon="el-icon-search" @click="getPermissionList"></el-button>
          </template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="addPermission">添加权限</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-table :data="permissionList" max-height="800" border stripe>

    <el-table-column label="标识" prop="id"></el-table-column>
    <el-table-column label="权限名" prop="name" :width="100">
      <template v-slot="scope">
        <router-link :to="{path: 'permission-info', query: {id: scope.row.id}}">{{scope.row.name}}</router-link>
      </template>
    </el-table-column>
    <el-table-column label="权限编码" prop="code" :width="200"></el-table-column>
    <el-table-column label="上级权限" prop="parent_id" :width="200"></el-table-column>
    <el-table-column label="后端接口" prop="uri" :width="200"></el-table-column>
    <el-table-column label="前端路由" prop="path" :width="200"></el-table-column>
    <el-table-column label="权限类型" prop="type" :formatter="typeFormat"></el-table-column>
    <el-table-column label="图标" prop="icon" :width="200"></el-table-column>
    <el-table-column label="状态" prop="status" :formatter="statusFormat"></el-table-column>
    <el-table-column label="创建时间" prop="create_time" :width="100"></el-table-column>
    <el-table-column label="更新时间" prop="update_time" :width="100"></el-table-column>
    <el-table-column label="操作" id="operation" :width="200">
      <template v-slot="scope">
        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
          <el-button type="primary" icon="el-icon-edit" v-btnlimit="'edit'" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
          <el-button type="danger" icon="el-icon-delete" v-btnlimit="'delete'" size="mini" @click="handleDeletePermission(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="设置" placement="top" :enterable="false">
          <el-button type="warning" icon="el-icon-setting" v-btnlimit="'setting'" size="mini" @click="showSettingDialog(scope.row.id)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="queryInfo.page_num"
    :page-sizes="[5, 10, 30, 50]"
    :page-size="queryInfo.page_size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total" background>
</el-pagination>
</div>
</template>

<script>
import {deleteAuthority, getAuthorities} from "../../api/user";

export default {
  name: "Permissions",
  data() {
    return {
      user_id: "",
      queryInfo: {
        keyword: "",
        page_num: 1,
        page_size: 5,
      },
      permissionList: [],
      total: 0,
    }
  },
  created() {
    this.getPermissionList();
  },
  methods: {
    async getPermissionList() {
      console.log(this.queryInfo)
      await getAuthorities(this.queryInfo).then(res => {
        if (res.code !== 200) {
          return this.$message.error("Query permissions data failed: " + res.message)
        }
        this.permissionList = res.data
        this.total = res.total
      }).catch(ex => {
        console.error(ex)
        return this.$message.error("Exception" + ex)
      });
    },
    handleSizeChange(newSize){
    this.queryInfo.page_size = newSize;
    this.getPermissionList()
    },
    handleCurrentChange(newPage){
      this.queryInfo.page_num = newPage;
      this.getPermissionList()
    },
    addPermission(){
      this.$router.push("add-permission").catch(() => {});
    },

    statusFormat(row){
      if (row.status === 0) {
        return "禁用"
      } else if (row.status === 1) {
        return "启用"
      }
    },

    typeFormat(row){
      if (row.type === 1) {
        return "目录"
      } else if (row.type === 2) {
        return "菜单"
      } else if (row.type === 3) {
        return "按钮"
      }
    },

    handleDeletePermission(row) {
      const form = {
        id: row.id,
      }
      this.$confirm(`此操作将删除 ${row.name}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAuthority(form).then(res => {
        if (res.code !== 200){
          console.log(res.message)
          return this.$message.error(res.message)
        }
        this.$message.success('Message: ' + res.message)
        this.$forceUpdate()
      }).catch(err => {
        console.error(err)
        return this.$message.error(err)
      })
      }).catch(err => {
        return this.$message.info(err)
      })
    },

    async showEditDialog(id){
        await this.$router.push({path: "permission-info", query: {id: id}})
    },

    showSettingDialog(id){
      this.$router.push({path: "permission-setting", query: {id: id}})
    },
  },
}
</script>

<style scoped>
#breadcrumb {
  font-size: 20px;
  background-color: white;
}

#operation {
  width: 100px;
}

.el-pagination {
  background-color: white;
}
</style>